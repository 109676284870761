import React from "react";
import HowItWorksItem from "./HowItWorksItem";
import { Link } from "react-router-dom";

const HomeFourHowWorks = () => {
  return (
    <>
      <section className="howitworks pt-180">
        <div className="floating-btn-container">
          <Link
            className="primary_btn w-100 btn-icon btn-hide no-icon-btn center"
            to="/login"
          >
            Request SICK NOTE now{" "}
          </Link>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
              <div className="section-title text-center pos-rel mb-75">
                <div className="section-icon">
                  <img
                    className="section-back-icon"
                    src="img/section/section-back-icon.png"
                    alt=""
                  />
                </div>
                <div className="section-text pos-rel">
                  <h5>How It Works</h5>
                  <h2>
                    Steps to obtain a sick note (doctor certificate) using{" "}
                    <i>SouthVanMed.com</i>
                  </h2>
                </div>
                <div className="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row pos-rel d-flex justify-content-between">
            <HowItWorksItem
              icon="1"
              title="Sign Up"
              moveIcon="move-icon"
              description={"Fill up your information to get started."}
            />
            <HowItWorksItem
              icon="2"
              title="Medical Info"
              moveIcon="move-icon"
              description={
                "Enter your medical and social history including medical conditions if any"
              }
            />
            <HowItWorksItem
              icon="3"
              title="Payment"
              description={"Proceed with payment"}
            />

            <HowItWorksItem
              icon="4"
              title="Waiting for approval"
              description={
                "Doctor will looking into the information submitted and will send you messages or call you to clarify your medical conditions and information. In some cases you may also need to attend the clinic for an in-person assessment prior to obtaining the sick note."
              }
              moveIcon="move-icon"
            />

            <HowItWorksItem
              icon="5"
              title="Sick Note Obtained"
              description={
                "The sick note requested will be emailed to you upon approval."
              }
              moveIcon="move-icon"
            />

            <HowItWorksItem
              icon="6"
              title="Sick Note Validation"
              description={"You or your employer or school can check whether this Sick Note is valid on our website by using the Sick Note Code on your Sick Note."}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeFourHowWorks;
