import React from 'react';
import { Helmet } from 'react-helmet';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';

import SickNoteArea from './SickNoteArea/SickNoteArea';


const SickNoteLanding = () => {




    return (<>
        <Helmet>
            <title>South Vancouver Medical Clinic | Login</title>
            <link rel="canonical" href="https://southvanmed.com/sicknote" />

            <meta name="description" content="The South Vancouver Medical Clinic sick note page " />
        </Helmet>

        <CommonPageHeader title="Request a sick note (doctor certificate) online" subtitle="Sick Note"  pageDescription="The South Vancouver Medical Clinic is a full-service medical clinic located on Marine Drive at Main Street in Vancouver. Our services include in-person and virtual doctor appointments."/>

        <SickNoteArea />

    </>)
}



export default SickNoteLanding;