import React, { Component } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
    lat: 49.2092, lng: -123.100042
};

class MyComponents extends Component {
  render() {
    return (
      <LoadScript
        googleMapsApiKey="AIzaSyAJQidlQhaDYu9-OcB-Dl6r0jHJDF5vRL0"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
        >
          { /* Child components, such as markers, info windows, etc. */ }
          <><Marker position={{ lat: 49.2092, lng: -123.100042 }} /></>
        </GoogleMap>
      </LoadScript>
    )
  }
}

export default MyComponents;