import React, { useState } from 'react';
import {connect} from 'react-redux';

import { Link } from 'react-router-dom';
import SidebarLoggedIn from '../../../components/Shared/Sidebar/SidebarLoggedIn';

import useGlobalContext from '../../../hooks/useGlobalContext';
import {logoutAction} from '../../../actions/auth';
import { toggleLang } from '../../../actions/lang';
import { switchLang } from '../../../services/lang';



const NavBarLoggedIn = (props) => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();

   return (
      <>
         <header>
            {/* <!-- menu-area --> */}
            <div className={stickyMenu ? "sticky_menu header-menu-area header-padding transparrent-header sticky_navBar_bg" :
               "header-menu-area header-padding transparrent-header"}>
               <div className="container-fluid">
                  <div className="row d-flex align-items-center">
                     <div className="col-xl-2 col-lg-2 col-md-2">
                        <div className="logo pos-rel">
                           <Link to="/">
                              <img src="img/logo/SVM-LOGO5.png" alt="" style={{height:'48px'}}/>
                              {/* <span>South Vancouver Medical</span> */}
                           </Link>
                        </div>
                     </div>
                     <div className="col-xl-8 col-lg-8 col-md-8">
                        <div className="header__menu header-menu-white">
                           <nav id="mobile-menu">
                              <ul>
                              <li><Link to="/">Home</Link></li>

                              <li><Link to="#">Support +</Link>
                                 <ul className="submenu">
                                    <li><Link to="/clinic-policy">Clinic Policies</Link></li>
                                    <li><Link to="/faq">FAQ</Link></li>
                                    <li><Link to="/about">About us</Link></li>
                                    <li><Link to="/contact">Contact us</Link></li>

                                 </ul>
                              </li>

                              <li><Link to="#">Services +</Link>
                                 <ul className="submenu">
                                    <li><Link to="/book-appointment">Book Appointments</Link></li>
                                    <li><Link to="/immi">Immigration Medical Exam</Link></li>

                                    <li><Link to="/login">AskMedication &trade;
                              </Link></li>
                                    <li><Link to="/login">AskLabTest &trade;</Link></li>
                                    <li><Link to="/sicknote">Sick Note</Link></li>
                                 </ul>
                              </li>
                              <li style={{color: 'white'}}>|</li>
                                 <li><Link to="/dashboard">Dashboard</Link></li>
                                 <li><Link to="/account">My Account</Link></li>
                                 <li><Link to="/appointments/me">My appointments</Link></li>
                                 <li><Link to="/message">My Messages</Link></li>

                              </ul>
                           </nav>
                        </div>
                        <div onClick={handleShow} className="side-menu-icon d-lg-none text-end">
                           <button className="side-toggle border-0 bg-transparent">
                              <i className="fas fa-bars text-white"></i> </button>
                        </div>
                     </div>
                     <div className="col-xl-2 col-lg-2 col-md-2 d-none d-lg-block d-xl-block">
                        <div className="header-right f-right">
                           <a href="#" className="primary_btn btn-icon btn-icon-green"  onClick={()=>{props.logoutAction()}}>
                           <span>
                              <i className="fas fa-sign-out-alt"></i>
                              </span>

                              Logout</a> 
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>

         <SidebarLoggedIn show={show} handleClose={handleClose} />
      </>
   );
};

const mapDispatchToProps = {
   logoutAction,
   toggleLang
}

const mapStateToProps = state => {
   
   return {
       isEn: state.lang.lang == "en" || typeof state.lang.lang == "undefined",
       user: state.auth.user,
   };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBarLoggedIn);