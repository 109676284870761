import { medicalInfoService } from '../services';
import { medicalInfoConstant } from '../Constants';
import { alertAction } from './alert.action';
import { getPersonId, errorHandling, isEmptyObj } from '../_helpers';
import { sickNoteService } from '../services/sicknote.service';
import { setAlert } from "../actions/alert";
import { SET_SICK_NOTE } from "../actions/type";
import { history } from '../_helpers';
import {setToLoading,setToLoaded} from './loader';

export const sickNoteAction = {
    getByPerson,
    getById,
    change,
    save,
    validateSickNote
};

function getByPerson() {
    return dispatch => {
        medicalInfoService.getByPerson()
            .then(
                res => {
                    const array = res.data.medicalInfo;
                    const askmedUser = res.data.user;
                    const medicalInfoObj = getLatestMedicalInfo(array);
                    const pastMedicalInfoObjs = getPastMedicalInfo(array);
                    const actionObj = init(medicalInfoObj, askmedUser);

                    dispatch({
                        type: medicalInfoConstant.GET_BY_PERSON,
                        ...actionObj,
                        pastMedicalInfoObjs,
                        askmedUser,
                    });
                },
                error => {
                    errorHandling('medicalInfo.action.getByPerson', error, dispatch);
                }
            );
    };
}

function getById(id) {
    return dispatch => {
        medicalInfoService.getById(id)
            .then(
                res => {
                    const actionObj = init(res.data);

                    dispatch({
                        type: medicalInfoConstant.GET_BY_ID,
                        ...actionObj
                    });
                },
                error => {
                    errorHandling('medicalInfo.action.getById', error, dispatch);
                }
            );
    };
}

function init(medicalInfoObj, user) {
    const noMedicalInfo = isEmptyObj(medicalInfoObj);

    if (noMedicalInfo) {
        medicalInfoObj = {
            Person: user.Person._id,
            Sex: '',
            MedicalConditions: [],
            Surgeries: [],
            AllergyToMedication: [],
            NoMedicalCondition: false,
            NoSurgery: false,
            NoAllergy: false,
            MedicationsCurrentlyTaken: [],
            supplements: '',
            otherItems: '',
            AlcoholHistory: '',
            SmokingHistory: '',
            defaultPharmacy: null,
            otherMedicalConditions: null,
            otherSurgery: null,
            otherAllergy: null,
          
        };
    } else {
        medicalInfoObj.NoMedicalCondition = medicalInfoObj.MedicalConditions.length === 0 && medicalInfoObj.otherMedicalConditions === null;
        medicalInfoObj.NoSurgery = medicalInfoObj.Surgeries.length === 0 && medicalInfoObj.otherSurgery === null;
        medicalInfoObj.NoAllergy = medicalInfoObj.AllergyToMedication.length === 0 && medicalInfoObj.otherAllergy === null;
    }

    return {
        medicalInfoObj,
        noMedicalInfo
    };
}

function getLatestMedicalInfo(medicalInfoList) {
    let medicalInfoObj = {};

    // get the latest medicalInfo object
    if (medicalInfoList !== undefined && medicalInfoList.length > 0) {
        // medicalInfoObj = [...medicalInfoList].pop();
        medicalInfoObj = [...medicalInfoList][0];
    }

    return medicalInfoObj;
}

function getPastMedicalInfo(medicalInfoList) {
    let pastMedicalInfo = [];

    if (medicalInfoList !== undefined && medicalInfoList.length > 1) {
        pastMedicalInfo = [...medicalInfoList].slice(1);   
    }
    return pastMedicalInfo;
}

function change(medicalInfoObj) {
    return {
        type: medicalInfoConstant.CHANGE,
        medicalInfoObj: medicalInfoObj
    };
}

function save(medicalInfoObj) {
    return dispatch => {
        medicalInfoService.save(medicalInfoObj)
            .then(
                res => {
                    const actionObj = init(res.data);
                    
                    dispatch({
                        type: medicalInfoConstant.SAVE,
                        ...actionObj
                    });


                    dispatch(alertAction.success('Medical information updated successfully.'));
                },
                error => {
                    errorHandling('manage-data.action.buildDrugs', error, dispatch);
                }
            );
    };
}

function saveSicknote (sickNoteObj){
        return async (dispatch) => {
            try{
                const res = await sickNoteService.save(sickNoteObj);
                
              
                
            } catch(error) {

            }

        }
}

 function validateSickNote(sickNoteObj) {
  return async (dispatch) => {
    try {
      dispatch(setToLoading());
      const res = await sickNoteService.validateSickNote(sickNoteObj);
      dispatch(saveSickNoteAction({
          isPrivate: !res.data.extras.isBCServiceValid,
          ...sickNoteObj
      }))
      dispatch(setToLoaded());
      history.push('/sick-note/payment');
    //   return dispatch(setAlert('Sick Note validate succeed', 'success'))
      
    } catch (error) {
      dispatch(setToLoaded());
      if (error.response) {
          console.log(error.response.data)
        // return dispatch(setAlert(error.response.data, 'danger',5000,'alert-fixed'));
        return {success: false, error: error.response.data}

      }
      return {success: false, error: "Sick Note validate failed"}
    //   return dispatch(setAlert('Sick Note validate failed', 'danger',5000,'alert-fixed'));
    }
  };
}

function saveSickNoteAction(sickNoteObj) {
    return ({
        type: SET_SICK_NOTE,
        payload: sickNoteObj
    })
}
