import React from 'react';

const HomeTwoSingleAbout = ({ icon, title, subtitle, spacing, description}) => {
    return (
        <>
            <div className="col-xl-6 col-lg-6 col-md-6">
                <div className={`feature-box m${spacing}`}>
                    {/* <div className="feature-small-icon mb-35">
                        <img src={`img/icon/s-fea-icon-${icon}.png`} alt="" />
                    </div> */}
                    <div className="feature-small-content" style={{textAlign: 'center'}}>
                        <h3 className=''>{title}</h3>
                        <h6 className="green-color text-up-case letter-spacing mb-20">{subtitle || ''}</h6>

                        <img src={`img/howtogetthere/step${icon}-min.jpg`} alt="" width={'90%'} height={'200px'} style={{borderRadius:'5px'}}/>
                        <p>{description}</p>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomeTwoSingleAbout;