import React from 'react';

import { Container, Col, Row } from "react-bootstrap";
import ReCAPTCHA from 'react-google-recaptcha';
import Util from '../../../services/Utility'
import moment from 'moment';
import { Helmet } from "react-helmet";

import MonthDropDown from '../../../components/Layout/Dropdowns/MonthDropDown';
import YearDropDown from '../../../components/Layout/Dropdowns/YearDropDown';
import DayDropDown from '../../../components/Layout/Dropdowns/DayDropDown';
import CountryList from '../../../components/Layout/Dropdowns/CountryList';
import PharmacySelect from '../../../components/Layout/Dropdowns/PharmacySelect';
import config from '../../../config';


import { registerAction } from '../../../actions/auth';
import { getAllPharmacy } from '../../../actions/pharmacy';
import {setToLoading,setToLoaded} from '../../../actions/loader';

import { setAlert } from '../../../services/sweetAlert';

import InputVerifier from "../../../services/InputValidationService";

import ModalComponent from '../../../components/Layout/Modal';
import { connect } from "react-redux";
import { scroller } from 'react-scroll';




const errorKeys = {
   "firstName": "firstNameError",
   "lastName": "lastNameError",
   "gender": "genderError",
   "address": "addressError",
   "city": "cityError",
   "province": "provinceError",
   "postal": "postalError",
   "email": "emailError",
   "password": "passwordError",
   "healthCardProvince": "healthCardProvinceError",
   "healthCardNumber": "healthCardNumberError",
   "country": "countryError",
   "birthDay": "birthDayError",
   "birthMonth": "birthMonthError",
   "birthYear": "birthYearError",
   "emailConfirm": "confirmEmailError",
   "passwordConfirm": "passwordConfirmError",
   "healthCardNumberConfirm": "confirmHealthcardNumberError",
   "healthCardNumberGlobal": "healthCardNumberGlobalError",
   "passwordGlobal": "passwordGlobalError",
   "emailGlobal": "emailGlobalError",
   "mobilePhone": "mobileError",
   "homePhone": "homePhoneError",
   "internationalPhone": "internationalPhoneError",
   "recaptcha": "recaptchaError",
   "preferredPharmacy": "preferredPharmacyError",
   "agreementCheck": "agreementCheckError",

}


class RegisteArea extends React.Component {

   constructor(props) {
      super(props);

      this.state = {
         pharmacyList: this.props.pharmacy.list || [],

         items: {
            firstName: "",
            lastName: "",
            gender: "",
            address: "",
            city: "",
            province: "",
            postal: "",

            phone1: "",
            phone2: "",
            phone3: "",

            mobilePhone1: "",
            mobilePhone2: "",
            mobilePhone3: "",

            internationalPhone: "",
            email: "",
            emailConfirm: "",
            password: "",
            passwordConfirm: "",
            healthCardProvince: "",
            healthCardNumber: "",
            healthCardNumberConfirm: "",
            country: "Canada",
            birthDay: "",
            birthMonth: "",
            birthYear: "",
            preferredPharmacy: "",
            recaptcha: ""
         },
         validationErrors: [],
         alertMessage: "",
         showModal: false,
         modalMsg: "",
         isLoading: false,
         agreementChecked: false,
         errorMessage: {
            firstNameError: "",
            lastNameError: "",
            genderError: "",
            addressError: "",
            cityError: "",
            postalError: "",
            provinceError: "",
            mobileError: "",
            homePhoneError: "",
            internationalPhoneError: "",
            countryError: "",
            healthCardProvinceError: "",
            healthCardNumberError: "",
            emailError: "",
            confirmEmailError: "",
            passwordError: "",
            birthDayError: "",
            birthMonthError: "",
            birthYearError: "",
            mobileHomeIntRequiredError: "",
            passwordConfirmError: "",
            confirmHealthcardNumberError: "",
            healthCardNumberGlobalError: "",
            passwordGlobalError: "",
            emailGlobalError: "",
            recaptchaError: "",
            preferredPharmacyError: "",
            agreementCheckError: "",

         }
      };

      // this.changeListener = null;
      this.onChange = this.onChange.bind(this);
   }


   componentDidMount() {
      this._scrollToTop();
      if (this.props.pharmacy.list.length == 0)
         this._setToLoading();
      // this.props.getAllPharmacy();
   }

   componentDidUpdate(prevProps, prevState) {
      if (this.props.pharmacy.list !== prevProps.pharmacy.list) {
         this.setState({
            pharmacyList: this.props.pharmacy.list
         })
         this._setToLoaded();
      }
   }

   handleReCaptcha(val) {
      this.setState({
         items: {
            ...this.state.items,
            recaptcha: val
         }
      })
   }

   _scrollToTop() {
      scroller.scrollTo("register-container", {
         duration: 800,
         smooth: true,
         offset: -100
      });
   }

   _setToLoading() {
      this.props.setToLoading();
   }

   _setToLoaded() {
      this.props.setToLoaded();
   }

   onChange(event) {
      // event.preventDefault();
      if (event.target.name.includes("hone") || event.target.name.includes("healthCardNumber")) {
         let re = /^[0-9\b]+$/;
         if (event.target.value === '' || re.test(event.target.value)) {
            return this.setState({
               items: {
                  ...this.state.items,
                  [event.target.name]: event.target.value
               }

            })
         }
         return
      }

      if (event.target.name === "country" && event.target.value !== "Canada") {
         return this.setState({
            items: {
               ...this.state.items,
               healthCardProvince: "",
               healthCardNumber: "",
               healthCardNumberConfirm: "",
               [event.target.name]: event.target.value
            }

         })
      }

      if (event.target.name === "healthCardProvince" && event.target.value === "Other") {
         return this.setState({
            items: {
               ...this.state.items,
               healthCardNumber: "",
               healthCardNumberConfirm: "",
               [event.target.name]: event.target.value
            }

         })
      }
      let key = event.target.name;
      this.setState({
         items: {
            ...this.state.items,
            [event.target.name]: event.target.value
         }
      }, () => {
         if (this.state.items.birthDay && this.state.items.birthMonth && this.state.items.birthYear) {
            if (key === "birthDay" || key === "birthMonth" || key === "birthYear") {
               this.setShow(true);
            }
         }
      });
      // console.log(this.state)
   }

   setAlert(key, msg) {
      if (!key) return;
      if (this.state.errorMessage[key].length > 0) {
         return;
      }

      if (!Object.keys(this.state.errorMessage).includes(key)) {
         console.error("Invalid key provided", key)
         return;
      }

      this.setState(prev => ({
         errorMessage: {
            ...prev.errorMessage,
            [key]: msg
         }
      }));

      setTimeout(() => {
         this.setState(
            prev => ({
               errorMessage: {
                  ...prev.errorMessage,
                  [key]: ""
               }
            })
         )
      }, 10000);
   }



   mapKeysToString(key) {
      let obj = {
         "firstName": "First Name",
         "lastName": "Last Name",
         "gender": "Gender",
         "address": "Address",
         "city": "City",
         "province": "Province",
         "postal": "Postal",
         "email": "Email",
         "emailConfirm": "Confirm Email ",
         "password": "Password",
         "passwordConfirm": "Confirm Password",
         "healthCardProvince": "Health Card Province",
         "healthCardNumber": "Health Card Number",
         "healthCardNumberConfirm": "Confirm Health Card Number",
         "country": "Country",
         "birthDay": "Birth Day",
         "birthMonth": "Birth Month",
         "birthYear": "Birth Year",
         "preferredPharmacy": "Preferred Pharmacy"

      }
      return obj[key];
   }

   validate1() {

      let noMPIErrors = [];
      let emptyErrors = [];
      let invalidChars = [];
      let doNotMatchErrors = [];
      let lengthNotEqualErrors = [];

      this.setState({ validationErrors: [] });

      let stateKeys = Object.keys(this.state.items);
      if (this.state.items.healthCardProvince === "Other") {
         stateKeys = stateKeys.filter(k => k !== "healthCardNumber" && k !== "healthCardNumberConfirm")
      }
      if (this.state.items.country !== "Canada") {
         stateKeys = stateKeys.filter(k => k !== "healthCardNumber" && k !== "healthCardNumberConfirm" && k !== "healthCardProvince")
      }
      stateKeys.forEach(key => {
         // check empty on items except phone inputs
         if (!key.includes("hone") && key !== "recaptcha")
            InputVerifier.checkEmpty(this.state.items[key], key, emptyErrors);
      });

      stateKeys.forEach(key => {
         if (key !== "password" && key !== "passwordConfirm" && key !== "address" && key !== "email" && key !== "emailConfirm" && key !== "recaptcha" && key !== "preferredPharmacy") {
            InputVerifier.checkUnnecessaryCharacters(this.state.items[key], key, invalidChars);
         }
      });

      // either mobile or homePhone or internationalPhone is required
      noMPIErrors = Util.phoneEntryValidate(this.state.items, noMPIErrors);

      // check length 
      InputVerifier.checkLengthGreater(this.state.items.password, 8, "password", lengthNotEqualErrors);
      InputVerifier.checkLengthGreater(this.state.items.passwordConfirm, 8, "passwordConfirm", lengthNotEqualErrors);

      // check equality
      InputVerifier.checkEqual(this.state.items.password, this.state.items.passwordConfirm, "password", doNotMatchErrors);
      // check if email and email confirm is same
      InputVerifier.checkEqual(this.state.items.email, this.state.items.emailConfirm, "email", doNotMatchErrors);

      if (this.state.items.healthCardProvince !== "Other" &&
         this.state.items.country === "Canada"
      ) {
         InputVerifier.checkEqual(this.state.items.healthCardNumber, this.state.items.healthCardNumberConfirm, "healthCardNumber", doNotMatchErrors);

         InputVerifier.checkLengthGreater(this.state.items.healthCardNumber, 6, "healthCardNumber", lengthNotEqualErrors);
         InputVerifier.checkLengthGreater(this.state.items.healthCardNumberConfirm, 6, "healthCardNumberConfirm", lengthNotEqualErrors);
      }

      // display error

      console.log(emptyErrors);

      if (emptyErrors.length > 0) {
         emptyErrors.forEach(key => {
            let msg = `${this.mapKeysToString(key)} cannot be empty.`;
            // this.props.setAlert(msg,"danger");
            this.setAlert(errorKeys[key], msg);
         })
         this.setState({
            validationErrors: emptyErrors
         })
         return;
      }


      if (Util.noMobileHomeIntFound(this.state.items)) {
         let msg = "Either Mobile Phone, Home Phone or International Phone is required for registration."
         this.setAlert("mobileHomeIntRequiredError", msg)
         return;
      }

      if (invalidChars.length > 0) {
         invalidChars.forEach(k => {
            let msg = `Invalid symbol detected in ${this.mapKeysToString(k)} field.`;
            this.setAlert(errorKeys[k], msg);
         })
         this.setState({
            validationErrors: invalidChars
         })
         return;
      }
      if (doNotMatchErrors.length > 0) {
         doNotMatchErrors.forEach(k => {
            let msg = `${this.mapKeysToString(k)} and Confirm ${this.mapKeysToString(k)} do not match.`;

            console.log(k)
            this.setAlert(errorKeys[k.concat("Global")], msg);
         })
         this.setState({
            validationErrors: doNotMatchErrors
         })
         return;
      }


      if (lengthNotEqualErrors.length > 0) {
         lengthNotEqualErrors.forEach(k => {
            let num = {
               "password": 8,
               "passwordConfirm": 8,
               "healthCardNumber": 6,
               "healthCardNumberConfirm": 6
            };

            let msg = `The length of ${this.mapKeysToString(k)} must be greater than ${num[k]}.`
            this.setAlert(errorKeys[k.concat("Global")], msg)
         })
         this.setState({
            validationErrors: lengthNotEqualErrors
         })
         return;
      }


      let errors = emptyErrors.concat(doNotMatchErrors).concat(invalidChars).concat(noMPIErrors);

      //remove duplicates
      errors = errors.filter((item, index) => errors.indexOf(item) === index);
      // this.setState({ validationErrors: errors });
      return errors.length === 0;
   }

   validate2() {
      let errors = [];
      if (this.state.items.mobilePhone1 || this.state.items.mobilePhone2 || this.state.items.mobilePhone3) {
         let isValid = true;
         if (!Util.isNumber(this.state.items.mobilePhone1) || this.state.items.mobilePhone1.length !== 3) {
            isValid = false;
            errors.push("mobilePhone1");
         }
         if (!Util.isNumber(this.state.items.mobilePhone2) || this.state.items.mobilePhone2.length !== 3) {
            isValid = false;
            errors.push("mobilePhone2");
         }
         if (!Util.isNumber(this.state.items.mobilePhone3) || this.state.items.mobilePhone3.length !== 4) {
            isValid = false;
            errors.push("mobilePhone3");
         }
         if (!isValid) {
            let msg = `The Mobile Phone Number you entered is invalid`;
            this.setAlert(errorKeys["mobilePhone"], msg)
            // this.props.setAlert(msg,"danger");
         }
      }

      if (this.state.items.phone1 || this.state.items.phone2 || this.state.items.phone3) {
         let isValid = true;
         if (!Util.isNumber(this.state.items.phone1) || this.state.items.phone1.length !== 3) {
            isValid = false;
            errors.push("phone1");
         }
         if (!Util.isNumber(this.state.items.phone2) || this.state.items.phone2.length !== 3) {
            isValid = false;
            errors.push("phone2");
         }
         if (!Util.isNumber(this.state.items.phone3) || this.state.items.phone3.length !== 4) {
            isValid = false;
            errors.push("phone3");
         }
         if (!isValid) {
            let msg = `The Home Phone Number you entered is invalid.`
            // this.props.setAlert(msg,"danger")
            this.setAlert(errorKeys["homePhone"], msg)
         }

      }

      if (this.state.items.internationalPhone) {
         if (!Util.isNumber(this.state.items.internationalPhone)) {
            errors.push("internationalPhone");
            let msg = `The International Phone is invalid.`;
            // this.props.setAlert(msg,"danger")
            this.setAlert(errorKeys["internationalPhone"], msg);

         }
         if (this.state.items.internationalPhone.length < 9) {
            if (!errors.includes("internationalPhone")) {
               errors.push("internationalPhone");
            }
            let msg = `The minimum International Phone digits has to be 9 or more.`;
            // this.props.setAlert(msg,"danger")
            this.setAlert(errorKeys["internationalPhone"], msg);
         }
      }

      // checked only on bc residents whose hin number is not empty
      if (this.state.items.healthCardNumber && this.state.items.healthCardProvince === "British Columbia" && !Util.hinValidation(this.state.items.healthCardNumber)) {
         errors.push("healthCardNumber");
         let msg = `The BC healthcard number is invalid.`
         // this.props.setAlert(msg,"danger");
         this.setAlert(errorKeys["healthCardNumberGlobal"], msg)

      }

      if (!this.state.agreementChecked) {
         errors.push("agreementCheck");
         let msg = "Please mark checkbox below to continue.";
         this.setAlert(errorKeys['agreementCheck'], msg);
      }

      if (!this.state.items.recaptcha) {
         errors.push("recaptcha")
         let msg = "Please validate you are not a bot."
         this.setAlert(errorKeys['recaptcha'], msg);
      }

      console.log(errors);
      if (errors.length > 0) {
         this.setState({
            validationErrors: errors
         });
         return false;
      }

      return true;

   }

   async createAccount() {
      // console.log(this.state)
      this._setToLoading();

      if (!this.validate1() || !this.validate2()) {
         this._setToLoaded();
         if (this.captcha) {
            this.captcha.reset();
         }
         this.setState({
            recaptcha: ""
         })
         return;
      }

      try {

         let phone = this.state.items.phone1 + this.state.items.phone2 + this.state.items.phone3;
         let mobilePhone = this.state.items.mobilePhone1 + this.state.items.mobilePhone2 + this.state.items.mobilePhone3;

         let items = Object.assign({ phone, mobilePhone }, this.state.items); //deep copy of state items
         // trim whitespace for all input field except password and password confirm
         Object.keys(items).forEach(k => {
            if (k !== "password" && k !== "passwordConfirm" && k !== 'preferredPharmacy')
               items[`${k}`] = items[`${k}`].trim();
         })
         items.email = items.email.split(" ").join("").toLowerCase();
         items.emailConfirm = items.emailConfirm.split(" ").join("").toLowerCase();

         let response = await this.props.registerAction(items);// await Auth.register(items);
         this._setToLoaded();

         if (response.data.success) {

            setAlert({
               icon: "success",
               message: "Registration Successful. Please check your email inbox for a confirmation email. Please also check your spam and junk folders. Click the link found in the email within 6 hours to confirm your email address."
            });

            // this.setState({
            //    alertMessage:
            //       "Registration Successful. Please check your email inbox for a confirmation email. Please also check your spam and junk folders. Click the link found in the email within 6 hours to confirm your email address."
            // });
            return;
         }

         // create account failed
         if (this.captcha) {
            this.captcha.reset();
         }

         setAlert({
            icon: "success",
            html:response.data.extras.message || "Registration failed, Please check your input and try again."
         });

         this.setState({
            recaptcha: ""
         });

      } catch (err) {
         console.log(err);
         if (this.captcha) {
            this.captcha.reset();
         }

         this._setToLoaded();
         setAlert({
            icon: "success",
            message:"Unknown error occurred, please contact support team"
         });
         
         return this.setState({
            recaptcha: ""
         });
      }
   }

   changeCheckbox = (event) => {
      this.setState({
         agreementChecked: event.target.checked
      });
   }

   renderMessage() {
      let bcMessage = "* Please enter the correct B.C. Services Card number. This number cannot be changed at a later time. Also ensure that your first name, family name and date of birth match the information on your B.C. Services Card. If any information is incorrect, you may need to pay a non-refundable $250.00 to book an appointment.";
      let charge5 = "* If your health card is out-of-province (except Quebec), then booking an appointment with a provider incurs a non-refundable fee of $5.00.";
      let charge200 = "* Non-refundable booking fee $250.00";

      if (this.state.items.country !== "Canada") {
         return (<div>{charge200}</div>)
      } else {
         switch (this.state.items.healthCardProvince) {
            case "":
               return null;
            case "British Columbia":
               return (<div>{bcMessage}</div>)
            case "Quebec":
            case "Other":
               return (<div>{charge200}</div>)
            default:
               return (<div>{charge5}</div>)
         }

      }


   }



   renderPreferedPharmacy() {

      return <>
         <hr />
         <Row>
            <Col sm={12} md={12}>
               <div className={this.state.errorMessage.preferredPharmacyError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.preferredPharmacyError}</div>
               <div className="register-label">What is your preferred pharmacy ?</div>
               <PharmacySelect
                  name="preferredPharmacy"
                  pharmacyList={this.state.pharmacyList}
                  onChange={this.onChange}
                  className={Util.applyInputStyles(this.state.validationErrors, "preferredPharmacy", [
                     "form-control"
                  ])}
               />

            </Col>
         </Row>
         <hr />
      </>
   }


   setShow(show) {
      let date = `${this.state.items.birthYear}-${this.state.items.birthMonth}-${this.state.items.birthDay}`;
      let years = moment().diff(moment(date), 'years');
      let msg = <p>Please confirm that you are <b>{years} years old</b> and your date of birth is <b>{this.state.items.birthDay}-{this.state.items.birthMonth}-{this.state.items.birthYear} (DD-MM-YYYY)</b>.<br />If you enter the wrong date of birth, you may be charged a non-refundable $250.00 for bookings.</p>;

      if (!show) {
         // cancel clicked
         return this.setState({
            showModal: show,
            items: {
               ...this.state.items,
               birthDay: "",
               birthMonth: "",
               birthYear: ""
            }
         })
      }

      this.setState({
         showModal: show,
         modalMsg: msg
      })
   }
   onModalConfirm() {
      this.setState({
         showModal: false
      })
   }



   render() {
      return <>
         <Helmet>
            <title>Sign up | South Vancouver Medical Clinic | Vancouver doctor</title>
            <link rel="canonical" href="https://southvanmed.com/register" />
            <meta name="description" content="South Vancouver medical clinic registration page, register now to get access to all services." />
            <meta name="keywords" content="South Vancouver medical clinic sign up, South Vancouver medical clinic register" />

         </Helmet>

         <section className="register-area pt-120 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="basic-register">
                        <ModalComponent
                           header="Date Of Birth Confirmation"
                           message={this.state.modalMsg}
                           show={this.state.showModal}
                           cancelText="Cancel"
                           confirmText="Confirm"
                           setShow={this.setShow.bind(this)}
                           confirm={this.onModalConfirm.bind(this)}
                           backdrop="static"
                        />
                        <h3 className="text-center mb-60">Sign Up</h3>
                        <h5>Please enter all information exactly as written on healthcare card. Do not use nicknames or other names. If booking an appointment for your child, create a new account with their name and healthcare card number. Do not use a parent's account or the appointment will be cancelled.</h5>

                        <Container>
                           <Row>
                              <Col md={6} >
                                 <div className={this.state.errorMessage.firstNameError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.firstNameError}</div>
                                 <div className="register-label">First Name</div>
                                 <input
                                    className={Util.applyInputStyles(this.state.validationErrors, "firstName", [
                                       "input-full-width"
                                    ])}
                                    type="text"
                                    name="firstName"
                                    placeholder=""
                                    onChange={this.onChange}
                                 />
                              </Col>
                              <Col md={6} >
                                 <div className={this.state.errorMessage.lastNameError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.lastNameError}</div>
                                 <div className="register-label">Last Name</div>
                                 <input
                                    className={Util.applyInputStyles(this.state.validationErrors, "lastName", [
                                       "input-full-width"
                                    ])}
                                    type="text"
                                    name="lastName"
                                    placeholder=""
                                    onChange={this.onChange}
                                 />
                              </Col>
                           </Row>

                           <Row>
                              <Col md="4">
                                 <div className={this.state.errorMessage.genderError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.genderError}</div>
                                 <div className="register-label">Gender</div>
                                 <select
                                    className={Util.applyInputStyles(this.state.validationErrors, "gender", [
                                       "input-full-width"
                                    ])}
                                    name="gender"
                                    defaultValue=""
                                    onChange={this.onChange}
                                 >
                                    <option value=""></option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    {/* <option value="Transgendered">Transgendered</option> */}
                                    <option value="Other">Other</option>
                                 </select>
                              </Col>
                              <Col>
                                 <Row className="mb-0">
                                    <Col>
                                       <div className="register-label"> Date of Birth</div>
                                    </Col>
                                 </Row>
                                 <Row className="mb-0">
                                    <Col sm={12} md={4} lg={4}>
                                       <div className={this.state.errorMessage.birthDayError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.birthDayError}</div>
                                       <DayDropDown
                                          classStyle={Util.applyInputStyles(this.state.validationErrors,
                                             "birthDay", [
                                             "input-full-width"
                                          ])}
                                          name="birthDay"
                                          value={this.state.items.birthDay}
                                          onChange={this.onChange}
                                       />
                                    </Col>
                                    <Col sm={12} md={4} lg={4}>
                                       <div className={this.state.errorMessage.birthMonthError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.birthMonthError}</div>
                                       <MonthDropDown
                                          classStyle={Util.applyInputStyles(this.state.validationErrors, "birthMonth", [
                                             "input-full-width"
                                          ])}
                                          name="birthMonth"
                                          value={this.state.items.birthMonth}
                                          onChange={this.onChange}
                                       />
                                    </Col>
                                    <Col sm={12} md={4} lg={4}>
                                       <div className={this.state.errorMessage.birthYearError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.birthYearError}</div>
                                       <YearDropDown
                                          classStyle={Util.applyInputStyles(this.state.validationErrors, "birthYear", [
                                             "input-full-width"
                                          ])}
                                          value={this.state.items.birthYear}
                                          name="birthYear"
                                          onChange={this.onChange}
                                       />
                                    </Col>
                                 </Row>
                              </Col>
                           </Row>
                           <Row>
                              <Col>
                                 <div className={this.state.errorMessage.addressError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.addressError}</div>
                                 <div className="register-label">Street Address</div>
                                 <input
                                    className={Util.applyInputStyles(this.state.validationErrors, "address", [
                                       "pull-left",
                                       "input-full-width"
                                    ])}
                                    type="text"
                                    name="address"
                                    placeholder=""
                                    onChange={this.onChange}
                                 />
                              </Col>
                           </Row>
                           <Row>
                              <Col sm={12} md={3}>
                                 <div className={this.state.errorMessage.cityError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.cityError}</div>
                                 <div className="register-label">City</div>
                                 <input
                                    className={Util.applyInputStyles(this.state.validationErrors, "city", [
                                       "input-full-width"
                                    ])}
                                    type="text"
                                    name="city"
                                    onChange={this.onChange}
                                 />
                              </Col>
                              <Col sm={12} md={3}>
                                 <div className={this.state.errorMessage.provinceError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.provinceError}</div>
                                 <div className="register-label">Prov</div>
                                 <select
                                    className={Util.applyInputStyles(this.state.validationErrors, "province", [
                                       "input-full-width"
                                    ])}
                                    name="province"
                                    defaultValue=""
                                    onChange={this.onChange}
                                 >
                                    <option value=""></option>
                                    <option value="British Columbia">British Columbia</option>
                                    <option value="Alberta">Alberta</option>
                                    <option value="Manitoba">Manitoba</option>
                                    <option value="Nunavut">Nunavut</option>
                                    <option value="Nova Scotia">Nova Scotia</option>
                                    <option value="New Brunswick">New Brunswick</option>
                                    <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                                    <option value="Northwest Territories">Northwest Territories</option>
                                    <option value="Ontario">Ontario</option>
                                    <option value="Prince Edward Island">Prince Edward Island</option>
                                    <option value="Quebec">Quebec</option>
                                    <option value="Saskatchewan">Saskatchewan</option>
                                    <option value="Yukon">Yukon</option>
                                    <option value="Other">Other</option>
                                 </select>
                              </Col>
                              <Col sm={12} md={3}>
                                 <div className={this.state.errorMessage.postalError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.postalError}</div>
                                 <div className="register-label">Postal</div>
                                 <input
                                    className={Util.applyInputStyles(this.state.validationErrors, "postal", [
                                       "input-full-width"
                                    ])}
                                    type="text"
                                    name="postal"
                                    onChange={this.onChange}
                                 />
                              </Col>
                              <Col sm={12} md={3}>
                                 <div className={this.state.errorMessage.countryError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.countryError}</div>
                                 <div className="register-label">Country</div>
                                 <CountryList
                                    classStyle={Util.applyInputStyles(this.state.validationErrors, "country", [
                                       "input-full-width"
                                    ])}
                                    name="country"
                                    defaultValue="Canada"
                                    onChange={this.onChange}
                                 />
                              </Col>
                           </Row>
                           <Row>
                              <Col>
                                 <div className={this.state.errorMessage.mobileHomeIntRequiredError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.mobileHomeIntRequiredError}</div>
                              </Col>
                           </Row>
                           <Row >
                              <Col sm={12} md={6} lg={4} className="mb-1">
                                 <div className={this.state.errorMessage.mobileError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.mobileError}</div>
                                 <div className="register-label">Mobile Phone</div>
                                 <div className="phone-label pull-left">(</div>
                                 <input
                                    id="mobilePhone1"
                                    className={Util.applyInputStyles(this.state.validationErrors, "mobilePhone1", [
                                       "input-one-fourth-width",
                                       "pull-left"
                                    ])}
                                    type="text"
                                    name="mobilePhone1"
                                    placeholder={"XXX"}
                                    onChange={this.onChange}
                                    value={this.state.items.mobilePhone1}
                                    maxLength={3}
                                 />
                                 <div className="phone-label pull-left">)</div>
                                 <input
                                    id="mobilePhone2"
                                    className={Util.applyInputStyles(this.state.validationErrors, "mobilePhone2", [
                                       "input-one-fourth-width",
                                       "pull-left"
                                    ])}
                                    type="text"
                                    name="mobilePhone2"
                                    placeholder={"XXX"}
                                    onChange={this.onChange}
                                    value={this.state.items.mobilePhone2}
                                    maxLength={3}
                                 />
                                 <div className="phone-label pull-left"> - </div>
                                 <input
                                    id="mobilePhone3"
                                    className={Util.applyInputStyles(this.state.validationErrors, "mobilePhone3", [
                                       "input-one-fourth-width",
                                       "pull-left"
                                    ])}
                                    type="text"
                                    name="mobilePhone3"
                                    placeholder={"XXXX"}
                                    onChange={this.onChange}
                                    value={this.state.items.mobilePhone3}
                                    maxLength={4}
                                 />
                              </Col>

                              <Col sm={12} md={6} lg={4}>
                                 <div className={this.state.errorMessage.homePhoneError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.homePhoneError}</div>
                                 <div className="input-full-width register-label">Home Phone</div>
                                 <div className="phone-label pull-left">(</div>
                                 <input
                                    id="phone1"
                                    className={Util.applyInputStyles(this.state.validationErrors, "phone1", [
                                       "input-one-fourth-width",
                                       "pull-left"
                                    ])}
                                    type="text"
                                    name="phone1"
                                    placeholder={"XXX"}
                                    onChange={this.onChange}
                                    value={this.state.items.phone1}
                                    maxLength={3}
                                 />
                                 <div className="phone-label pull-left">)</div>
                                 <input
                                    id="phone2"
                                    className={Util.applyInputStyles(this.state.validationErrors, "phone2", [
                                       "input-one-fourth-width",
                                       "pull-left"
                                    ])}
                                    type="text"
                                    name="phone2"
                                    placeholder={"XXX"}
                                    onChange={this.onChange}
                                    value={this.state.items.phone2}
                                    maxLength={3}
                                 />
                                 <div className="phone-label pull-left"> - </div>
                                 <input
                                    id="phone3"
                                    className={Util.applyInputStyles(this.state.validationErrors, "phone3", [
                                       "input-one-fourth-width",
                                       "pull-left"
                                    ])}
                                    type="text"
                                    name="phone3"
                                    placeholder={"XXXX"}
                                    onChange={this.onChange}
                                    value={this.state.items.phone3}
                                    maxLength={4}
                                 />
                              </Col>

                              <Col sm={12} md={6} lg={4}>
                                 <div className={this.state.errorMessage.internationalPhoneError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.internationalPhoneError}</div>
                                 <div className="register-label">International phone</div>
                                 <input
                                    id="internationalPhone"
                                    className={Util.applyInputStyles(this.state.validationErrors, "internationalPhone", [
                                       "input-full-width"
                                    ])}
                                    type="text"
                                    name="internationalPhone"
                                    placeholder=""
                                    onChange={this.onChange}
                                    value={this.state.items.internationalPhone}
                                 />
                              </Col>
                           </Row>
                           {this.renderPreferedPharmacy()}
                           {this.state.items.country === "Canada" ?
                              <Row>
                                 <Col md={6}>
                                    <div className={this.state.errorMessage.healthCardProvinceError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.healthCardProvinceError}</div>
                                    <div className="register-label">Health Card Province</div>
                                    <select
                                       className={Util.applyInputStyles(this.state.validationErrors, "healthCardProvince", [
                                          "input-full-width"
                                       ])}
                                       name="healthCardProvince"
                                       defaultValue=""
                                       onChange={this.onChange}
                                    >
                                       <option value=""></option>
                                       <option value="British Columbia">British Columbia</option>
                                       <option value="Alberta">Alberta</option>
                                       <option value="Manitoba">Manitoba</option>
                                       <option value="Nunavut">Nunavut</option>
                                       <option value="Nova Scotia">Nova Scotia</option>
                                       <option value="New Brunswick">New Brunswick</option>
                                       <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                                       <option value="Northwest Territories">Northwest Territories</option>
                                       <option value="Ontario">Ontario</option>
                                       <option value="Prince Edward Island">Prince Edward Island</option>
                                       <option value="Quebec">Quebec</option>
                                       <option value="Saskatchewan">Saskatchewan</option>
                                       <option value="Yukon">Yukon</option>
                                       <option value="Other">I Do Not Have A Canadian Healthcare Card</option>
                                    </select>
                                 </Col>
                              </Row>
                              : null}
                           {(this.state.items.country === "Canada" && this.state.items.healthCardProvince !== "Other") ? <>
                              <Row>
                                 <Col>
                                    <div className={this.state.errorMessage.healthCardNumberGlobalError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.healthCardNumberGlobalError}</div>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col md={6}>
                                    <div className={this.state.errorMessage.healthCardNumberError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.healthCardNumberError}</div>
                                    <div className="register-label">Enter Health Card Number (numbers only, no letters, dashes, or spaces)</div>
                                    <input
                                       className={Util.applyInputStyles(this.state.validationErrors, "healthCardNumber", [
                                          "input-full-width"
                                       ])}
                                       type="text"
                                       name="healthCardNumber"
                                       onChange={this.onChange}
                                       value={this.state.items.healthCardNumber}
                                    />
                                 </Col>
                                 <Col md={6}>

                                    <div className={this.state.errorMessage.confirmHealthcardNumberError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.confirmHealthcardNumberError}</div>

                                    <div className="register-label">Confirm Health Card Number</div>
                                    <input
                                       className={Util.applyInputStyles(this.state.validationErrors, "healthCardNumberConfirm", [
                                          "input-full-width"
                                       ])}
                                       type="text"
                                       name="healthCardNumberConfirm"
                                       onChange={this.onChange}
                                       value={this.state.items.healthCardNumberConfirm}
                                    />
                                 </Col>
                              </Row> </>
                              : null}
                           <Row>
                              <Col>
                                 <div className={this.state.errorMessage.emailGlobalError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.emailGlobalError}</div>
                              </Col>
                           </Row>
                           <Row>
                              <Col sm={12} md={6}>
                                 <div className={this.state.errorMessage.emailError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.emailError}</div>
                                 <div className="register-label">Email (used for Login)</div>
                                 <input
                                    className={Util.applyInputStyles(this.state.validationErrors, "email", [
                                       "input-full-width"
                                    ])}
                                    type="text"
                                    name="email"
                                    placeholder=""
                                    onChange={this.onChange}
                                 />
                              </Col>
                              <Col sm={12} md={6}>

                                 <div className={this.state.errorMessage.confirmEmailError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.confirmEmailError}</div>
                                 <div className="register-label">Confirm Email</div>
                                 <input
                                    className={Util.applyInputStyles(this.state.validationErrors, "emailConfirm", [
                                       "input-full-width"
                                    ])}
                                    type="text"
                                    name="emailConfirm"
                                    placeholder=""
                                    onChange={this.onChange}
                                 />
                              </Col>
                           </Row>
                           <Row>
                              <Col>
                                 <div className={this.state.errorMessage.passwordGlobalError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.passwordGlobalError}</div>
                              </Col>
                           </Row>
                           <Row>
                              <Col sm={12} md={6}>
                                 <div className={this.state.errorMessage.passwordError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.passwordError}</div>
                                 <div className="register-label">Choose Password</div>
                                 <input
                                    className={Util.applyInputStyles(this.state.validationErrors, "password", [
                                       "input-full-width"
                                    ])}
                                    type="password"
                                    name="password"
                                    onChange={this.onChange}
                                 />
                              </Col>
                              <Col sm={12} md={6}>
                                 <div className={this.state.errorMessage.passwordConfirmError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.passwordConfirmError}</div>
                                 <div className="register-label">Confirm Password</div>
                                 <input
                                    className={Util.applyInputStyles(this.state.validationErrors, "passwordConfirm", [
                                       "input-full-width"
                                    ])}
                                    type="password"
                                    name="passwordConfirm"
                                    onChange={this.onChange}
                                 />
                              </Col>
                           </Row>
                           <Row>
                              <Col className="checkbox">
                                 <div className={this.state.errorMessage.agreementCheckError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.agreementCheckError}</div>
                                 <div className="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" className="custom-control-input" id="agreementCheck" name="agreementCheck"
                                       checked={this.state.agreementChecked} onChange={this.changeCheckbox} />
                                    <label className="custom-control-label" htmlFor="agreementCheck">
                                       I consent to SouthVanMed.com transmitting information, including information related to my medical conditions, via email.
                                    </label>
                                 </div>
                              </Col>
                           </Row>
                           <Row>
                              <Col>
                              </Col>
                              <Col>
                                 <br />
                                 <div className={this.state.errorMessage.recaptchaError.length > 0 ? "alert-self-danger" : null}>{this.state.errorMessage.recaptchaError}</div>
                                 <div style={{ float: "right" }}>
                                    <ReCAPTCHA
                                       ref={e => (this.captcha = e)}
                                       sitekey={config.captcha_key}
                                       onChange={this.handleReCaptcha.bind(this)}
                                    />
                                 </div>
                                 <br />
                              </Col>
                           </Row>
                           <Row>
                              <Col className="keywords">
                                 {this.renderMessage()}
                              </Col>
                           </Row>
                           <Row>
                              <Col>
                                 <br />
                                 {/* <FilledButton
              action={this.createAccount.bind(this)}
              styles={["pull-right"]}
              >
              Sign Up
              </FilledButton> */}
                                 <button className="primary_btn btn-icon" onClick={this.createAccount.bind(this)}><span> <i class="fas fa-check"></i></span>Submit</button>
                              </Col>
                           </Row>
                        </Container>

                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>

   };
}

const mapStateToProps = state => ({
   isLoading: state.loader.isLoading,
   alertMessage: state.auth.alertMessage,
   pharmacy: state.pharmacy,
})

const mapDispatchToProps = {
   setToLoading,
   setToLoaded,
   registerAction, 
   getAllPharmacy
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisteArea);
