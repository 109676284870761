import React from 'react';



const FAQ = () => {

    return <>
        <div className="sicknote-faq">
            <div className="row">
                <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
                    <div className="section-title text-center pos-rel mb-75">
                        <div className="section-icon">
                            {/* <img className="section-back-icon" src="img/section/section-back-icon.png" alt="" /> */}
                        </div>
                        <div className="section-text pos-rel">
                            <h5>Frequent asked questions</h5>
                            <h2>FAQs</h2>
                        </div>
                        <div className="section-line pos-rel">
                            <img src="img/shape/section-title-line.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="sicknote-faq-container">
                <div class="row py-2">
                    <div class="col-lg-12">
                        <div id="bootstrap-accordion_12" class="panel-group accordionStyles accordion" role="tablist"
                            aria-multiselectable="true">
                            <div class="row">
                                <div class="col-lg-6">

                                    <div class="card">
                                        <div class="card-header" role="tab" id="headingOne">
                                            <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                                                data-core="" href="#collapse1_3" aria-expanded="false" aria-controls="collapse1">

                                                <h4 class="mbr-fonts-style display-7">1. How long of a sick note can you provide?
                                                    <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse1_3" class="panel-collapse noScroll collapse " role="tabpanel"
                                            aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div class="panel-body">
                                                <p class="mbr-fonts-style panel-text  display-4">
                                                    We only provide a maximum of 7 consecutive days for sick notes using our online service. If you need to be off for a longer time you must book an appointment at SouthVanMed.com and discuss with a provider
                                                </p>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="card">
                                        <div class="card-header" role="tab" id="headingOne">
                                            <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                                                data-core="" href="#collapse1_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 class="mbr-fonts-style display-7">2. Are sick notes refundable?
                                                    <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse1_21" class="panel-collapse noScroll collapse " role="tabpanel"
                                            aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div class="panel-body">
                                                <p class="mbr-fonts-style panel-text  display-4">
                                                    No, sick notes are not refundable.
                                                </p>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div class="col-lg-6">

                                <div class="card">
                                        <div class="card-header" role="tab" id="headingOne">
                                            <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse"
                                                data-core="" href="#collapse7_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 class="mbr-fonts-style display-7">3. I need a more complex sick note / medical certificate. Can the doctor provide a sick note that is different from the sample displayed on your website?
                                                    <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse7_21" class="panel-collapse noScroll collapse " role="tabpanel"
                                            aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div class="panel-body">
                                                <p class="mbr-fonts-style panel-text  display-4">
                                                No, we only provide sick notes similar to the one shown on our website. If you have more complex needs, or need forms to be filled out, we ask you to book an appointment at SouthVanMed.com with a provider.
                                                </p>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>


                    </div>
                </div>


            </div>
        </div>
    </>
}

export default FAQ;