import React from 'react';
import { Helmet } from 'react-helmet';

import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';

import FaqArea from './FaqArea';
const FAQ = () => {
    return (
        <>
            <Helmet>
                <title>FAQs | South Vancouver Medical Clinic | Vancouver doctor</title>
                <link rel="canonical" href="https://southvanmed.com/faq" />
                <meta name="description" content="The frequently asked questions for south vancouver medical clinic web services" />
                <meta name="keywords" content="user not found, later for my appointment, email not found, cancel appointment,forgot password, obtain lab result, obtain medical records" />

            </Helmet>
            <CommonPageHeader title="Frequent asked questions" subtitle="FAQ" />
            <FaqArea />
        </>
    );
};

export default FAQ;