

import { 
    AUTH_ERROR,
    USER_LOADED,
    LOGOUT,
    LOGIN_SUCCESS,
    RESET_PASSWORD,
    UPDATE_PASSWORD,
    UPDATE_MESSAGE,
    USER_UPDATED,
    USER_FAMILY_DOC_SET,
    USER_FAILED_TO_CREATE_ACCOUNT
  } from './type';
  
  import axios from 'axios';


  import setAuthToken from '../utils/setAuthToken';
  import Config from '../config';
  

  // Actions
  import {setAlert} from './alert';
  import {setToLoaded,setToLoading} from './loader';
  
//   import {resetAppointmentStore} from './appointments';
//   import {clearTypeAction, clearSelectedDoctorAction} from './doctors';
//   import {resetScheduleStore} from './schedule';
  
  const config = {
      headers:{
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store, must-revalidate"
      }
  };
  /*\
  *@ Loaduser
  */
  
  export const loadUser = () => async dispatch => {
      dispatch(setToLoading())
      if (localStorage.accessToken) {
          setAuthToken(localStorage.accessToken);

        //   const storageHost = createHost([
        //       {
        //           origin: `${Config.askmedication}`,
        //           allowedMethods: ['get', 'set', 'remove'],
        //       },
        //   ]);
      }
  
    try{
        let res = await axios.get(`${Config.service}/me`,config);
        dispatch(setToLoaded())
      //   console.log(res)
        dispatch({
            type:USER_LOADED,
            payload:res.data
        })
  
    }catch(err){
        dispatch(setToLoaded())
        dispatch({
            type:AUTH_ERROR
        })
    }
  
  }
  
  export const setShouldUpdate = (params) => async dispatch => {
      try{
          dispatch(setToLoading());
          let url = `${Config.service}/me/shouldupdate`;
          let data = {
              shouldUpdate:params.shouldUpdate,
              hasFamilyDoc:params.hasFamilyDoc
          }
          
          let res = await axios.post(url,data,config);
          dispatch({
              type:USER_UPDATED,
              payload:res.data
          })
          dispatch(setToLoaded());

      }catch(err){
        dispatch(setToLoaded());
          throw err;
      }
  }
  
  export const setUser = (data) => async dispatch => {
      //   console.log(localStorage);
        dispatch(setToLoading())

        if(localStorage.accessToken){
            setAuthToken(localStorage.accessToken)
        }
      
        try{
          let res = await axios.post(`${Config.service}/me`,data,config);
      
        //   console.log(data)
          dispatch({
              type:USER_UPDATED,
              payload:res.data
          })
  
          dispatch(setToLoaded())
          
        }catch(err){
          dispatch(setToLoaded())
          throw err;
        }
      
      }
  export const setFamilyDoc = (data) => dispatch => {
      dispatch({
          type:USER_FAMILY_DOC_SET,
          payload:data
      })
  }
  
  export const registerAction = (form) => async dispatch => {
      let data =  form;
      try {
          // console.log(Config.service)
          let res = await axios.post(`${Config.service}/register`,data,config);
          return res;
      }catch(err){
          throw err;
      }
  }
  
  // export const failedAccountCreationAction = (reason) => async dispatch => {
  //     dispatch({
  //         type:USER_FAILED_TO_CREATE_ACCOUNT,
  //         reason: reason
  //     })
  // }
  
  export const loginAction = (email,password,recaptcha) => async dispatch => {
      let data =  {
          'method': 'email',
          'email': email,
          'password': password,
          'recaptcha':recaptcha
      }
      try {
          let res = await axios.post(`${Config.service}/login`,data,config);
          if (res.data.success) {
              dispatch({
                  type:LOGIN_SUCCESS,
                  payload: {
                      token: res.data.extras.accessToken
                  }
              });
              // console.log('dispatching loaduser')
              dispatch(loadUser());
          } else {
            //   let alertMsg = res.data.extras.message || 'Login Failed';
            //   dispatch(setAlert(alertMsg,'danger'))
              dispatch({
                  type:AUTH_ERROR,
              });
          }
          return res.data;
  
  
      }catch(err){
          console.log(err)
          // let errors = err.response ? err.response.data.errors:null;
          // if(errors){
          //     errors.forEach(e=>{
          //         dispatch(setAlert(e.msg,"danger"))
          //     })
          // }
          dispatch(setAlert('Login Failed, Network error.','danger'))

          dispatch({
              type:AUTH_ERROR,
          })
          throw err;
      }
  }
  
  export const loginUserAction = (accessToken) => async dispatch => {
      var currentAccessToken = localStorage.getItem('accessToken');
  
      // AppointmentStore.reset();
      // DoctorsStore.reset();
  
      if (currentAccessToken !== accessToken) {
          localStorage.setItem('accessToken', accessToken);
      }
      if(localStorage.getItem('accessToken')){
          axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('accessToken');
      } else {
          delete axios.defaults.headers.common['x-auth-token'];
      }
  
      dispatch({
          type:LOGIN_SUCCESS,
          payload: {
              token: accessToken
          }
      });
      dispatch(loadUser());
  }
  
  export const loginUserWithoutRedirectAction = (accessToken) => async dispatch => {
      localStorage.setItem('accessToken', accessToken);
  }
  
  export const logoutAction = () => dispatch => {
      dispatch(resetStoreAction())
      dispatch({
          type:LOGOUT
      })
  }
  
  export const failedLoginACtion = (reason) => async dispatch => {
      localStorage.removeItem('accessToken');
  
      dispatch({
          type: AUTH_ERROR,
          reason: reason
      });
  }
  
  export const forgetPasswordValidate = (email,birthDay,birthMonth,birthYear,hin,recaptcha) => async dispatch => {
      try{
          let data = {
              email,
              birthDay,
              birthMonth,
              birthYear,
              hin,
              recaptcha
          }
          let res = await axios.post(`${Config.service}/login/forgot/validate`,data,config);
          return res.data;
  
      }catch(err){
          throw err;
      }
  }
  
  export const passwordResetAction = (email) => async dispatch => {
      let data = {
          'email': email
      };
      try{
          let res = await axios.post(`${Config.service}/login/forgot`,data,config)
          return res.data;
      }catch(err){
          throw err;
      }
  }
  
  export const sendResetCodeAction = (email, code, password) => async dispatch => {
      let data = {
          'email': email,
          'code': code,
          'password': password
      };
      try{
          let res = await axios.post(`${Config.service}/login/reset`,data,config)
          return res.data; 
      }catch(err){
          throw err;
      }
  }
  
  export const updateMessageAction = (message) => async dispatch => {
      dispatch({
          type: UPDATE_MESSAGE,
          alertMessage: message
      });  
  }
  
  export const resetStoreAction = () => dispatch => {
    //   dispatch(clearTypeAction());
    //   dispatch(clearSelectedDoctorAction())
    //   dispatch(resetScheduleStore())
    //   dispatch(resetAppointmentStore())
  }