import { messagesService } from '../services/messages';
import { MESSAGE_REPLIED, MESSAGE_CHANGE, MESSAGE_LOADED } from './type2.js';
import { setAlert } from './alert';



export const messageAction = {
    sentToMOA,
    change,
    setMessage,
    sendReplyByLink
};


function change(sendMessageObj) {
    return {
        type: MESSAGE_CHANGE,
        sendMessageObj: sendMessageObj
    };
}

function setMessage(message) {
    return {
        type: MESSAGE_LOADED,
        payload: message,
        // isReplied: false // TODO: change this condition to conditional
    };
}

// function change(sendMessageObj) {
//     return {
//         type: MESSAGE_CHANGE,
//         sendMessageObj: sendMessageObj
//     };
// }


function sendReplyByLink(id, sendMessageObj) {
    return dispatch => {


        return messagesService.updateRepliesByLink(id, sendMessageObj)
            .then(
                res => {
                    const { isReplied } = res.data.extras;

                    if (isReplied) {
                        dispatch(setAlert("Your message was successfully sent to the doctor.", 'success'));
                        dispatch(setMessage(res.data.extras.payload))

                    } else {

                        dispatch({
                            type: MESSAGE_REPLIED,
                            isReplied,
                        });
                    }
                    return res.data;
                },
                error => {
                    dispatch(setAlert('message sent failed', 'danger'));
                }
            );
    };

}


function sentToMOA( message) {
    return dispatch => {
     

       return messagesService.sentMessageToMOA( message)
            .then(
                res => {
                    const success = res.success;
                    
                    if (success){
                        
                        window.setAlert = setAlert
                        console.log(setAlert)
                        dispatch(setAlert("Your message was successfully sent to our medical office assistants", 'success', 10000, 'position-fixed w-100'));
                        
                    
                    } else {

                        dispatch(setAlert(res.message, 'danger'));
                    }              
                },
                error => {
                     dispatch(setAlert('message sent failed', 'danger'));
                }
            );
    };

}





