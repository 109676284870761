import React from 'react';
import {connect} from 'react-redux';

import { Button, Container } from "react-bootstrap";

import { history } from '../../_helpers'
import CommonPageHeader from "../CommonPageHeader/CommonPageHeader";

import Dialog from '@material-ui/core/Dialog';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const SickNotePaymentSuccess =  (props) =>  {

    const user = props.user || {};
        return <>

        <CommonPageHeader title="Sick Note Payment Successful" subtitle="sick-note | payment | success"/>

        <div className="sicknote-success" style={{height: '800px'}}>
            
        <Dialog 
            open={true}
            fullWidth={true}
        >
            <DialogTitle>
                Sick note request submitted successfully
            </DialogTitle>
            <DialogContent>
                Doctor Budai will get back to you within 24 hours through email. The email we have on file is {user.email}.
            </DialogContent>
            <DialogActions>
                <Button onClick={()=> history.push('/dashboard')}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
        </div>
        </>
}

const mapStateToProps = state => ({
    user: state.auth.user,
  })

export default connect(mapStateToProps)(SickNotePaymentSuccess);