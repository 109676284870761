import React from 'react';
import { Link } from 'react-router-dom';
import HomeTwoSingleAbout from '../../../components/HomeTwoSingleAbout/HomeTwoSingleAbout';

const HowToGetToOurClinic = () => {
   return (
      <>
         <section className="about-area about-area-mid pt-120 pb-90">
            <div className="container">
               <div className="row">
                  

                  <div className="col-xl-6 col-lg-12 col-md-11">
                     <div className="about-right-side pt-25 mb-30">
                        <div className="about-title mb-20">
                           {/* <h5 className="pink-color">About Us</h5> */}
                           <h2>How to get to our clinic</h2>
                        </div>
                        <div className="about-text">
                           <p>Our clinic is located on the second floor of the Marine Drive Superstore. <br/>
                              350 SE Marine Dr, Vancouver, BC V5X 2S5 
                           </p><br/>
                        </div>
                        <div className="about-text-list mb-40">
                           <ul>
                              <li><i className="fa fa-check"></i><span>Enter the Superstore main entrance</span></li>
                              <li><i className="fa fa-check"></i><span>Turn immediately to the left</span></li>
                              <li><i className="fa fa-check"></i><span>Go up the stairs or take elevator located next to the store's Customer Service desk</span></li>
                              <li><i className="fa fa-check"></i><span>There is our clinic!</span></li>
                           </ul>
                        </div>
                        <div className="button-area">
                           <Link to="/contact" className="primary_btn btn-icon ml-0"><span>+</span>Contact us</Link>
                        </div>
                     </div>
                  </div>

                  <div className="col-xl-6 col-lg-12 col-md-12">
                     <div className="row">

                        <HomeTwoSingleAbout icon="1" title="" spacing="b-40" subtitle={'STEP ONE'}/>
                        <HomeTwoSingleAbout icon="2" title="" spacing="t-40" subtitle={'STEP TWO'}/>
                        <HomeTwoSingleAbout icon="3" title="" spacing="b-30" subtitle={'STEP THREE'}/>
                        <HomeTwoSingleAbout icon="4" title="" spacing="t-40 mb-30" subtitle={'STEP FOUR'}/>

                     </div>
                  </div>

               </div>
            </div>
         </section>
      </>
   );
};

export default HowToGetToOurClinic;