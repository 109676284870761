import React from 'react';
import { Link } from 'react-router-dom';

const ServicesSingleItem = ({image,title, description, serviceLinks}) => {

    return (
        <>
            <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="service-box-3 mb-30 text-center">
                    <div className="service-thumb">
                    <img src={`img/dashboard/service-${image}.jpg`} alt="" />

                    </div>
                    <div className="service-content-box">
                        <div className="service-content">
                            <h3>{title}</h3>
                                {description}
                        </div>

                        {serviceLinks}
                        
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServicesSingleItem;