import React from 'react';
import { Helmet } from 'react-helmet';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';

import ImmigrationArea from './ImmigrationArea';
const Immigration = () => {
    return (
        <>
            <Helmet>
                <title>Immigration medical exam | South Vancouver Medical Clinic | Vancouver doctor</title>
                <link rel="canonical" href="https://southvanmed.com/immi" />
                <meta name="description" content="South Vancouver medical clinic provides immigration medical exam" />
                <meta name="keywords" content="Immigration medical exam, New zealand immigration medical exam, canada immigartion medical exam, Immigration medical exam near me, Immigration medical exam frequently asked questions" />

            </Helmet>
            <CommonPageHeader title="Immigration Medical Exam" subtitle="Immigration" />
            <ImmigrationArea />
        </>
    );
};

export default Immigration;