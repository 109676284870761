

import { 
    APPOINTMENT_SELECTED,
    APPOINTMENT_RESCHEDULE_SELECTED,
    APPOINTMENT_STATE_DID_CHANGE,
    APPOINTMENT_RESET,
    CLEAR_SELECTED_APPOINTMENT
} from './type';


export const selectAppointmentAction = (appointment) => async dispatch => {
    dispatch({
        type: APPOINTMENT_SELECTED,
        appointment: appointment
    });
}

export const setAppointmentStateAction = (state) => async dispatch => {
    dispatch({
        type: APPOINTMENT_STATE_DID_CHANGE,
        appointment: state
    });
}

export const rescheduleAppointmentAction = (appointment) => async dispatch => {
    dispatch({
        type: APPOINTMENT_RESCHEDULE_SELECTED,
        appointment: appointment
    });
}

export const clearSelectedAppointment = () => dispatch => {
    dispatch({
        type:CLEAR_SELECTED_APPOINTMENT
    })
}

export const resetAppointmentStore = () => async dispatch => {
    dispatch({
        type: APPOINTMENT_RESET,
    });
}