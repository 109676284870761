import React from 'react';
import { connect } from 'react-redux';
import Alert from 'react-bootstrap/Alert';

const AlertBox = (props) => {
    const { alerts } = props;
    if (alerts.alert.length > 0 && alerts !== null) {
        return alerts.alert.map(a => {
             return <Alert key={a.id} variant={`${a.alertType}`} className={a.className ? a.className : ''}>{a.msg}</Alert>
         })
     } else {
         return null;
     }

}


const mapStateToProps = state => ({
    alerts:state.alert
})
export default connect(mapStateToProps) (AlertBox);