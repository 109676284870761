import React from 'react';
import { Helmet } from 'react-helmet';

import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import AppointmentAreaSvm from './AppointmentArea/AppointmentAreaSvm';



const Appointment = () => {
    return (
        <>
            <Helmet>
                <title>Book Appointment | South Vancouver Medical Clinic | Vancouver doctor</title>
                <link rel="canonical" href="https://southvanmed.com/book-appointment" />
                <meta name="description" content="South Vancouver medical clinic offers various of type of appointment, book one with us today." />
                <meta name="keywords" content="Immigration medical exam, In-person appointment, telephone appointment, Driver medical exam, ICBC appointment, Tourists and visitors from outside of Canada, international student appointment, Foreign workers appointment, quebec resident medical appointment" />

            </Helmet>

            <CommonPageHeader title="Book Appointment" subtitle="Appointment" />
            <AppointmentAreaSvm />
            {/* <BookAppoinment/> */}

        </>
    );
};

export default Appointment;