import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { scroller } from 'react-scroll';
import { Translation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { Redirect, Link } from "react-router-dom";

import TermsPrivacyFee from '../../../components/Layout/TermsPrivacyFee/TermsPrivacyFee';

import PropagateLoader from '../../../components/Layout/PropagateLoader';

// components
// import Alert from '../../../components/Layout/Alert/Alert';

//actions
import { loginAction } from '../../../actions/auth';
import { setToLoading, setToLoaded } from '../../../actions/loader';

import { setLang } from '../../../actions/lang';
import { getLangCode } from '../../../services/lang'
    ;
import { setAlert } from '../../../services/sweetAlert';
// assets
import config from '../../../config';

const ReCAPTCHA = lazy(() => import('react-google-recaptcha'));



class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            recaptcha: '',
            isLoading: false

        }
    }

    componentDidMount() {
        this._scrollToTop();

        this.captcha && this.captcha.reset();
        console.log(this.props)
    }
    componentWillMount() {
        this.captcha && this.captcha.reset();
        // this.props.setLang(this.props.isEn ? "en": "cn")
    }

    _scrollToTop() {
        scroller.scrollTo("login-container", {
            duration: 800,
            smooth: true,
            offset: -200
        });

    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

    }

    handleReCaptcha(val) {
        this.setState({
            recaptcha: val
        })
    }

    _validateInput() {

        // let isInputValid = true;
        let errors = []

        if (this.state.email.length == 0) {
            errors.push('Please enter a valid email.')
        }

        if (this.state.password.length == 0) {
            errors.push('Password cannot be empty.')
        }

        if(errors.length > 0){

            setAlert({icon:'danger',message: errors[0]});
            return false;
        }
        return true;
    }

    async emailSubmit(e) {
        e.preventDefault();

        if (!this._validateInput()) return;

        this.props.setToLoading();

        // this.setState({
        //     isLoading: true
        // })


        try {

            let username = this.state.email.split(" ").join("").toLowerCase();
            let res = await this.props.loginAction(username, this.state.password, this.state.recaptcha);
            this.props.setToLoaded();

            if (!res.success) {
                this.captcha.reset();
                
                this.setState({
                    // isLoading: false,
                    recaptcha: ''
                })
                
                setAlert({icon:'danger',message: res.extras.message || 'Login Failed'})

            }

        } catch (e) {
            if (this.captcha) this.captcha.reset();
            this.props.setToLoaded();

            // show alert
            this.setState({recaptcha: ''})

            setAlert({icon:'danger', message: 'Login Failed'})

        }
    }


    render() {

        if (this.props.isAuthenticated) {
            return (
                <Redirect to='/dashboard'></Redirect>
            )
        }


        let buttonDisabled = !(this.state.email && this.state.password && this.state.recaptcha) || this.state.isLoading;

        return (
            <>
                <Helmet>
                    <title>South Vancouver Medical Clinic | Login</title>
                    <link rel="canonical" href="https://southvanmed.com/login" />

                    <meta name="description" content="The South Vancouver Medical Clinic login page " />
                </Helmet>

                <section className="login-area pt-100 pb-100" >
                    <div id="login-container" className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="basic-login">
                                    <h3 className="text-center mb-60">Login Here</h3>

                                    <form onSubmit={this.emailSubmit.bind(this)}>
                                        <Translation>
                                            {
                                                t => <>
                                                    <label htmlFor="email" className='mb-2'>Email Address <span>**</span></label>

                                                    <input
                                                        className="fill-content"
                                                        type="text"
                                                        name="email"
                                                        onChange={this.onChange.bind(this)}
                                                        value={this.state.email}
                                                        placeholder={t("Email address ...")}
                                                        autoComplete="username"
                                                    />
                                                </>
                                            }
                                        </Translation>
                                        <br />
                                        <Translation>
                                            {
                                                t =>
                                                    <>
                                                        <label htmlFor="password" className='mb-2'>Password <span>**</span></label>
                                                        <input
                                                            className="fill-content"
                                                            type="password"
                                                            name="password"
                                                            onChange={this.onChange.bind(this)}
                                                            value={this.state.password}
                                                            placeholder={t("Enter password")}
                                                            autoComplete="current-password"
                                                        />
                                                    </>
                                            }
                                        </Translation>
                                        <br />
                                        <div>
                                            <Suspense fallback={<PropagateLoader isLoading={true} />}>
                                                <ReCAPTCHA
                                                    ref={e => (this.captcha = e)}
                                                    className="g-recaptcha"
                                                    sitekey={config.captcha_key}
                                                    onChange={this.handleReCaptcha.bind(this)}
                                                    hl={getLangCode()}
                                                />
                                            </Suspense>
                                        </div>
                                        <br />

                                        <div className="link-container">
                                        <span className="minor-text">
                                                <Translation>
                                                    {
                                                        t => <>{t('New to South Vancouver Medical Clinic?')}</>
                                                    }
                                                </Translation>
                                            </span>
                                            <Link className="link" to="/register">
                                                <Translation>
                                                    {
                                                        t => <>{t('Sign Up')}</>
                                                    }
                                                </Translation>
                                            </Link>
                                            <br/>
                                            <Translation>
                                                {
                                                    t => <Link className="link" to="/forgotpassword">{t('Forgot password')}</Link>
                                                }
                                            </Translation>                                            
                                        </div>
                                        <br />
                                        <button className="primary_btn" 
                                        disabled={buttonDisabled} 
                                        type="submit">
                                            <Translation>
                                                {
                                                    t => <>{t('Log in')}</>
                                                }
                                            </Translation>
                                        </button>

                                    </form>
                                    <div className="or-divide"><span>or</span></div>

                                    <Link className="primary_btn w-100 btn-icon btn-hide" to='/immi'> <span><i className="fab fa-canadian-maple-leaf"></i></span>  <Translation>
                                        {
                                            t => <>{t('Book Immigration ')}<div className="hide-on-mobile">Medical Exam</div></>
                                        }
                                    </Translation></Link>

                                    <br />
                                    <br />
                                    <TermsPrivacyFee />
                                </div>
                                <div className="floating-btn-container">
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        alertMessage: state.auth.alertMessage,
        isEn: state.lang.lang == "en" || typeof state.lang.lang == "undefined"
    };
}


const mapDispatchToProps = {
    loginAction,
    setLang,
    setToLoading,
    setToLoaded
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);

