import React from 'react';






const PriceList = () => {

    return <>
        <div className="price-list-sicknote">
            <div className="row">
                <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
                    <div className="section-title text-center pos-rel mb-75">
                        <div className="section-icon">
                            {/* <img className="section-back-icon" src="img/section/section-back-icon.png" alt="" /> */}
                        </div>
                        <div className="section-text pos-rel">
                            {/* <h5>How the Sick Note looks like</h5> */}
                            <h2>Price List</h2>
                        </div>
                        <div className="section-line pos-rel">
                            <img src="img/shape/section-title-line.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="fee-item">

                {/* <h3 class="mt-4 center bold">Sick Note <span class="keywords">Fee </span></h3> */}
                <div class="bottom-line"></div>

                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="table-hover" scope="col"></th>
                            <th class="table-hover" scope="col">Price</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr class="table-hover">
                            <td>Valid B.C. Services Card (Care Card)</td>
                            <td>$50 <span class="note">including GST</span></td>
                        </tr>

                        <tr class="table-hover">
                            <td>No valid B.C. Services Card (Care Card)</td>
                            <td>$100 <span class="note">including GST</span></td>
                        </tr>

                    </tbody>
                </table>

            </div>
        </div>
    </>
}

export default PriceList;