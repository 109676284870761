import React from 'react'
import axios from 'axios'
import {ListGroup, Card,Row,Col} from 'react-bootstrap'
import config from '../../config';

import { cancelAppointment } from '../../actions/schedule'

import {rescheduleAppointmentAction,setAppointmentStateAction} from '../../actions/appointments';

import DoctorModel from '../../models/DoctorModel'
import AppointmentHistoryModel from '../../models/AppointmentHistoryModel'

import moment from 'moment'
import Utility from '../../services/Utility'
import { connect } from 'react-redux'
import Modal from '../../components/Layout/Modal';

import {SCHEDULE} from '../../actions/type';

import {setToLoaded,setToLoading} from '../../actions/loader'

import {history} from '../../_helpers'


class Appointment extends React.Component {

    cancel() {
        this.props.cancel(this.props.appointment);
    }

    reschedule() {
        this.props.reschedule(this.props.appointment);
    }

    renderRescheduleButton(){
        return (
            <div>
                <button
                    // action={this.reschedule.bind(this)}
                    onClick={this.reschedule.bind(this)}
                    id={'reschedule'} styles={['charcoal-style','fill-content','empty-button-padding','empty-button-margin']}
                >Reschedule Appointment</button>
            </div>
        )
    }

    renderCancelButton(isCancellable){

        let styles = ['charcoal-style','fill-content','empty-button-padding'];
        if(!isCancellable) {
            styles.push('line-through')
            styles.push('cancel-btn-disabled')
        }

        return (
                <div>
                    <button
                        className='primary_btn bg_secondary'
                        appointment={this.props.appointment}
                        onClick={this.cancel.bind(this)}
                        id={'cancel'} styles={styles}
                        disabled={!isCancellable}
                    >Cancel Appointment</button>
                </div>
        )
    }


    renderActions(isCancellable,isReschedulable) {
        if (!this.props.isEditable) {
            return null;
        }

        return (<>
                {this.renderCancelButton(isCancellable)}
                {/* disbale reschedule for now */}
                {/* {isReschedulable ?  this.renderRescheduleButton() : null} */}
            </>
        )
    }
    render() {
        if (!this.props.appointment) {
            return null;
        }
 
        let  doctor = this.props.doctor || {};
        
        let firstName = doctor.firstName ? doctor.firstName.split(" ")[0].slice(0,1) : 'N/A';
        let lastName = doctor.lastName ? doctor.lastName : 'N/A';
        let fullName = `Dr. ${firstName}. ${lastName}`
     
        let time = Utility.convertToBClocalTime(this.props.appointment.start.clone());
        let currentTime = Utility.convertToBClocalTime(moment());
        let isCancellable = this.props.appointment.paidAmount >= 140 ? false : true;
        if(isCancellable) {
            isCancellable = time.diff(currentTime,'minutes')/60 > 6 ?  true : false;
        }

        let isReschedulable = (time.diff(currentTime,'hours') > 24 && this.props.appointment.isReschedulable) ?  true : false;
        
        // let  isCancellable = time.diff(currentTime,'hours') > 6 ?  true : false;
        // console.log(isCancellable)
        // console.log('app time',time)
        // console.log('currentTime',currentTime)
        // console.log(time.diff(currentTime,'hours'))
        // console.log(this.props.appointment.isCancellable)

        return (
            <ListGroup.Item>
                <h5>{this.props.appointment.description}</h5>
                <div>{time.format('ddd MMM DD YYYY HH:mm')}</div>
                <div>{fullName || ''}</div><br/>
                {this.props.appointment.checkinCode ?
                    <div>Appointment code: {this.props.appointment.checkinCode}</div> : null
                }<br/>
                {this.renderActions(isCancellable,isReschedulable)}
            </ListGroup.Item>
        )
    }
}

class CancelledAppointment extends React.Component {

    render() {
        if (!this.props.appointment) {
            return null;
        }
        // console.log(moment(this.props.appointment.startTime))
        let startTime = Utility.convertToBClocalTime(moment(this.props.appointment.startTime));
        // console.log(this.props.appointment)

        // console.log(startTime)
        let dateToDay =  startTime.format('dddd MMM D');
        let dateToMM = startTime.format('HH:mm');
        let fullDate = `${dateToDay} at ${dateToMM}`;

        let doctor = this.props.appointment.doctor;
        let appointment = this.props.appointment;
        let firstName = 'N/A';
        let lastName = 'N/A';

        if(doctor) {
            firstName = doctor.firstName.split(" ")[0].slice(0,1);
            lastName = doctor.lastName ;

        }else if(appointment.displayInfo && appointment.displayInfo.doctorFirstName && appointment.displayInfo.doctorLastName){
            firstName = appointment.displayInfo.doctorFirstName.split(" ")[0].slice(0,1);
            lastName = appointment.displayInfo.doctorLastName;
        }
        


       let fullName = `Dr. ${firstName}. ${lastName}`;

        return (
            <ListGroup.Item>
                <div>{fullDate}</div>
                <div>{fullName || ''}</div><br/>
            </ListGroup.Item>
        )
    }
}

class AccountAppointments extends React.Component {
    constructor(props) {
        super(props);
        this._mounted = false;
        this.state = {
            mode: null,
            appointment: null,
            doctors: [],
            appointmentTobeRescheduled: null,
            upcomingAppointments: [],
            previousAppointments: [],
            cancelledAppointments:[],
            showRescheduleModal:false,
            showCancelModal:false,
            warningText:<>
                {/* <h3 className="app-alert-text alert-text ">
                    Paid appointments cannot be cancelled but can be rescheduled up to 24 hours before
                    the appointment time. Non-paid appointments can be cancelled or rescheduled up to
                    6 hours before the appointment time.
                </h3> */}
                </>,
            isLoading:false
        };

        this.cancel = this.cancel.bind(this);
        this.reschedule = this.reschedule.bind(this);
        this.reset = this.reset.bind(this);
    }

    cancel(appointment) {
        this.setState({
            mode: 'cancel',
            appointment: appointment,
            showCancelModal:true
        });
    }

    reschedule(appointment) {
        this.setState({
            appointmentTobeRescheduled: appointment
        })
        this.setShowRescheduleModal(true);
    }

    setShowRescheduleModal(bool) {
        this.setState({
            showRescheduleModal:bool
        })
    }

    setShowCancelModal(bool) {
        this.setState({
            showCancelModal:bool
        })
    }


    async confirmReschedule(){
        // console.log("rescheduleConfirmed")
        this.props.rescheduleAppointmentAction(this.state.appointmentTobeRescheduled);
        this.setShowRescheduleModal(false);
        this.props.setAppointmentStateAction(SCHEDULE);
        // history.push('schedule')
        history.push('/')
    }

    _didCancelAppointment() {
        
        // appointment did cancelled;

        this.props.setToLoaded();
        this.reset();

    }


    confirmCancellation() {
        // this.setState({isWorking: true});
        
        // set to loading  
        this.setShowCancelModal(false);
        this.props.setToLoading();
        this.props.cancelAppointment(this.state.appointment,this._didCancelAppointment.bind(this))

        // if (this.props.cancelAppointment(this.props.appointment, this._didCancelAppointment.bind(this))) {
        //     this.setState({isWorking: true});
        // } else {
        //     this.props.resetAction();
        // }
    }

    reset() {
        this.setState({
            mode: null,
            editingId: null
        });

        this.reloadAppointments();
    }

    async reloadAppointments() {
        try{
            this.setState({
                isLoading: true,
            })
            let res = await axios.get(`${config.service}/me/appointments`);
            this.setState({
                isLoading: false,
            })
            let response = res.data;
            if (response.doctors) {
                let doctors = {};
                response.doctors.map((item) => {
                    return new DoctorModel(item);
                }).forEach((item) => {
                    doctors[item.id] = item;
                });
                if(this._mounted){
                    this.setState({
                        doctors: doctors
                    });
                }
            }
            if (response.appointments) {
    
                let cancelledAppointments = response.appointments.filter(item => item.status ==='cancelled').map(
                    item => {
                        item.doctor = this.state.doctors[item.providerId];
                        return item;
                    }
                );
    
                let appointments = response.appointments.filter(item => item.status === 'booked' || item.status ==='registered' ).map((item) => new AppointmentHistoryModel(item)); //!== 'cancelled' || item.status !== 'oscarError'
    
                if(this._mounted){
                    this.setState({
                        upcomingAppointments: appointments.filter((item) => item.isEditable&&item.bookingId),
                        previousAppointments: appointments.filter((item) => !item.isEditable),
                        cancelledAppointments: cancelledAppointments
                    },()=>{  this.props.setToLoaded()});
                }
               
            }
            if (this._mounted){
                this.props.setToLoaded();
            }

            // this.props.setToLoaded();
            
        }catch(err){
            // this.props.setToLoaded();
            this.setState({
                isLoading: false,
            })
        }
       

    }
    componentDidMount() {
        this._mounted = true;
        // different warning text for private patients

        if(this.props.eligibility=='R' || this.props.eligibility=='NE'){
            this.setState({
                warningText:<>
                    <h1 className="app-alert-text alert-text ">
                        Private appointments <span className="bold">CANNOT</span> be cancelled.
                        Go to your My Appointments tab to reschedule up to 24 hours before your appointment.
                    </h1></>
            })
        }
        this.reloadAppointments();
    }
    componentWillUnmount(){
        this._mounted = false;
    }

    renderUpcoming() {
        if (this.state.upcomingAppointments.length < 1) {
            return (
                <Card>
                    <Card.Header>No upcoming appointments</Card.Header>                
                </Card>
            );
        }

        return (  
            <>

                <Modal
                    header={'Warning'}
                    message={'Are you sure you want to reschedule your appointment ?'}
                    show={this.state.showRescheduleModal}
                    setShow={this.setShowRescheduleModal.bind(this)}
                    confirm={this.confirmReschedule.bind(this)}
                    confirmText="Yes, reschedule appointment"
                    cancelText="No, do not reschedule appointment"
                />
                <Card>
                    <Card.Header>Upcoming Appointments</Card.Header>
                    <Card.Body>
                        <ListGroup>

                        <Row>
                            {this.state.upcomingAppointments.map((appointment, i) => {
                                return <Col sm={12} md={4} key={i}>
                                    <Appointment
                                        key={i}
                                        isEditable={true}
                                        cancel={this.cancel}
                                        reschedule={this.reschedule}
                                        appointment={appointment}
                                        doctor={this.state.doctors[appointment.doctorId]}
                                        appointmentType={this.props.appointmentType}
                                    />
                            </Col>

                            }
                            
                            )}
                        </Row>
                        </ListGroup>
                    </Card.Body>
                </Card>
            </>
        )
    }
    renderPrevious() {
        if (this.state.previousAppointments.length < 1) {
            return (
                <Card>
                    <Card.Header>No previous appointment history</Card.Header>                
                </Card>
            );
        }

        return (
            <Card>
                <Card.Header>Previous Appointments</Card.Header>
                <Card.Body>
                    <ListGroup>
                        <Row>
                        {this.state.previousAppointments.map((appointment, i) => {
                            return <Col sm={12} md={4} key={i}>
                                    <Appointment 
                                    key={i} 
                                    appointment={appointment}
                                    doctor={this.state.doctors[appointment.doctorId]}
                                    />
                                </Col>
                        }
                    )}

                        </Row>
                    
                    </ListGroup>
                </Card.Body>
            </Card>
        )
    }
    renderCancelled() {
        if (this.state.cancelledAppointments.length < 1) {
            return (
                <Card>
                    <Card.Header>No cancelled appointments</Card.Header>                
                </Card>
            );
        }

        return (
            <Card>
                <Card.Header>Cancelled Appointments</Card.Header>
                <Card.Body>
                    <ListGroup>
                    <>
                   <Row>
                        {this.state.cancelledAppointments.map((appointment, i) => {
                            return <Col sm={12} md={4} key={i}>
                            <CancelledAppointment key={i} doctors={this.state.doctors} appointment={appointment}/>
                            </Col>})
                        }
                   </Row>
                    </>
                    </ListGroup>
                </Card.Body>
            </Card>
        )
    }

    renderState() {
        switch (this.state.mode) {
            // case 'cancel':

            // this.setState({
            //     showCancelModal:true
            // })
            
                
            // return 
            // (
            //     <CancelAppointment
            //         appointment={this.state.appointment}
            //         resetAction={this.reset}
            //         cancelAppointment={this.props.cancelAppointment}
            //     />
            // );
            // case 'reschedule': return (
            //     <RescheduleAppointment
            //         id={this.state.editingId}
            //         resetAction={this.reset}
            //     />
            // );
            default: return (
                <div id="account-content" className='container'>
                    <section>
                        <Row>
                            {/* <Col sm={12} lg={3}><h1>My Appointments</h1> </Col> */}
                            {/* <Col sm={12} lg={9}>{this.state.warningText}</Col> */}
                        </Row>
                        {this.renderUpcoming()}
                        {this.renderPrevious()}
                        {this.renderCancelled()}
                    </section>
                </div>
            );
        }
    }
    render() {
        if(!this.props.isAuthenticated)
            return null;
        return  (
            <section>
                <Modal
                    header={'Warning'}
                    message={'Are you sure you want to cancel your appointment ?'}
                    show={this.state.showCancelModal}
                    setShow={this.setShowCancelModal.bind(this)}
                    confirm={this.confirmCancellation.bind(this)}
                    confirmText="Yes"
                    cancelText="No, do not cancel appointment"
                />
                {!this.state.isLoading ? this.renderState(): null }
            </section>
        );
    }
}

// class CancelAppointment extends React.Component {
//     constructor () {
//         super();

//         this.state = {
//             isWorking: false,
//             errorMessage: ''
//         };

//         this.confirm = this.confirm.bind(this);
//         this.back = this.back.bind(this);
//     }

//     closeAlert() {
//         this.setState({errorMessage: ''});
//     }
//     _didCancelAppointment(status){
//         if (status) {
//             return this.back();
//         }

//         this.setState({isWorking: false, errorMessage: 'An unknown error has occurred'});
//     }

//     confirm() {
//         this.setState({isWorking: true});
//         this.props.cancelAppointment(this.props.appointment,this._didCancelAppointment.bind(this))
//         // if (this.props.cancelAppointment(this.props.appointment, this._didCancelAppointment.bind(this))) {
//         //     this.setState({isWorking: true});
//         // } else {
//         //     this.props.resetAction();
//         // }
//     }

//     back() {
//         this.props.resetAction();
//     }

//     render() {
//         return (
//             <div id="account-content">
//                 { this.state.isWorking ? <DarkenedOverlay><SpinningControl/></DarkenedOverlay> : null }
//                 <AlertDialog message={this.state.errorMessage} onClose={this.closeAlert.bind(this)}/>
//                 <section>
//                     <h1>Cancel Appointment</h1><br/>
//                     <div id="cancel">Are you sure you wish to cancel this appointment?</div>
//                     <div className="charcoal">
//                         <Appointment id={this.props.id}/>
//                     </div>
//                 </section>
//                 <section>
//                     <button
//                         // action={this.back}
//                         onClick={this.back}
//                         id={'back-button'}
//                         styles={['charcoal-style']}
//                     >Back</button>

//                     <button
//                         // action={this.confirm}
//                         onClick={this.confirm}
//                         id={'confirm-button'}
//                         styles={['app-style', 'pull-right']}
//                     >Confirm Cancellation</button>
//                 </section>
//             </div>
//         )
//     }
// }

const mapStateToProps = state => ({
    // isLoading:state.loader.isLoading,
    isAuthenticated: state.auth.isAuthenticated,
    appointmentType:state.auth.user.appointmentType,
    shouldUpdate:state.auth.user.shouldUpdate,
    eligibility:state.auth.user.eligibility
})

const mapDispatchToProps = {
    rescheduleAppointmentAction,
    setToLoading,
    setToLoaded,
    cancelAppointment,
    setAppointmentStateAction
}
export default connect(mapStateToProps,mapDispatchToProps)(AccountAppointments)
