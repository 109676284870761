import React from 'react';
import HomeTwoSingleCount from '../../../components/HomeTwoSingleCount/HomeTwoSingleCount';

const HomeTwoCounter = () => {
    return (
        <>
            <section className="counter-wraper theme-bg pt-120">
                <div className="container">
                    <div className="row justify-content-around">

                        <HomeTwoSingleCount icon="4" counter="13" subtitle="Expert Doctors" />
                        <HomeTwoSingleCount icon="5" counter="10" subtitle="Number of Services" />
                        <HomeTwoSingleCount icon="6" counter="80000" subtitle="Patient Registered" />

                    </div>
                </div>
            </section>
        </>
    );
};

export default HomeTwoCounter;