import React from 'react';
import { Helmet } from 'react-helmet';

import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';

import DashboardArea from './DashboardArea';

const Dashboard = () => {
    return (
        <>
            <Helmet>
                <title>Dashboard | South Vancouver Medical Clinic</title>
                <link rel="canonical" href="https://southvanmed.com/dashboard" />

                <meta name="description" content="The South Vancouver Medical Clinic dasboard page " />
            </Helmet>

            <CommonPageHeader title="South Vancouver Medical Clinic" subtitle="dashboard" />
            <DashboardArea />
        </>
    );
};

export default Dashboard;