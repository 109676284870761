import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import HowItWorksItem from "./HowItWorksItem";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';




const LabTesting = () => {
  

  return (
    <>
     <Helmet>
            <title>Laboratory Testing | South Vancouver Medical Clinic</title>
            <link rel="canonical" href="https://southvanmed.com/lab-testing" />

            <meta name="description" content="Obtain basic lab testing using telemedicine." />
      </Helmet>

      <CommonPageHeader title='Laboratory Testing' subtitle='Lab Testing' pageDescription="Obtain basic lab testing using telemedicine"/>

      <div className="service-details-area pt-120 pb-100" style={{backgroundColor:"#f4f9fc"}}>
            <div className="container">
               <div className="row">
                  <div className="col-xl-12 col-lg-12">
                     <article className="service-details-box">

                        <div className="section-title pos-rel mb-45">
                           <div className="section-icon">
                              <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" />
                           </div>
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">Lab requisition on our website</h5>
                              <h2>Request Lab Testing</h2>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-30 lab-test"> 
                          <div>
                           <p>The lab test service provided by <strong>SouthVanMed.com</strong> allows you to conveniently request lab testing. To comply with the College of Physicians of British Columbia rules, we offer a limited number of tests, including basic ones for cholesterol, sugar, and thyroid levels. Once you submit your request, a physician will review your medical information. Following this review and any additional questions from the physician, a lab test form will be emailed to you. The form will also be sent directly to LifeLabs. You can then follow up with a physician after completing your lab testing.</p>
                           <p><strong>SouthVanMed.com</strong> offers a range of services, including laboratory testing through telemedicine. If you’re 19 years old or older, you can obtain basic lab testing by requesting a lab requisition on our website. You’ll then be directed to undergo testing at a LifeLabs location near you. Afterward, you can follow up either at the clinic or by phone to discuss your lab results with a doctor. It’s a convenient way to manage your health! 😊</p>
                           <p>If you have any other questions or need further assistance, feel free to ask! 🌟</p>
                           </div>
                           <div><img src="/img/dashboard/service-1.jpg" alt="lab testing" /> </div>
                        </div>
                      </article>
                     </div>
                   
                     <section className="howitworks pt-80">
        <div className="floating-btn-container">
          <Link
            className="primary_btn w-100 btn-icon btn-hide no-icon-btn center"
            to="/login"
          >
            Request LAB TESTING now{" "}
          </Link>
        </div>

        <div className="container">
          <div className="row pos-rel d-flex justify-content-between">
            <HowItWorksItem
              icon="1"
              title="Sign Up"
              moveIcon="move-icon"
              description={"Fill up your information and register on our website to get started."}
            />
            <HowItWorksItem
              icon="2"
              title="Medical Info"
              moveIcon="move-icon"
              description={
                "Enter your medical and social history including medical conditions if any."
              }
            />
            <HowItWorksItem
              icon="6"
              title="Submit your request"
              description={"Request a lab requisition."}
            />

            <HowItWorksItem
              icon="4"
              title="Waiting for review"
              description={
                "Once you submit your request, a physician will review your medical information."
              }
              moveIcon="move-icon"
            />

            <HowItWorksItem
              icon="5"
              title="Lab test form"
              description={
                "Following this review and any additional questions from the physician, a lab test form will be emailed to you. The form will also be sent directly to LifeLabs."
              }
              moveIcon="move-icon"
            />

            <HowItWorksItem
              icon="4"
              title="Undergo Testing and Obtain Results"
              description={
                "You’ll then be directed to undergo testing at a LifeLabs location near you. Afterward, you can follow up either at the clinic or by phone to discuss your lab results with a doctor."
              }
            />
         </div>
        </div>
      </section>
                  </div>
                  </div>
                  </div>
    </>
  );
};

export default LabTesting;
