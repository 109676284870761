import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../../components/Shared/Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';

const HomeTwoNavBar = (props) => {
   const {location} = props;
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();
   const isImmi = location.pathname === '/immi' ? true : false;

   return (
      <>
         <header>
            {/* <!-- menu-area --> */}
            <div className={stickyMenu ? "sticky_menu header-menu-area header-padding transparrent-header sticky_navBar_bg" :
               "header-menu-area header-padding transparrent-header"}>
               <div className="container-fluid">
                  <div className="row d-flex align-items-center">
                     <div className="col-xl-2 col-lg-2 col-md-6 col-6">
                        <div className="logo pos-rel">
                           <Link to="/">
                              <img src="img/logo/SVM-LOGO5.png" alt="" style={{height:'64px'}}/>
                              {/* <span>South Vancouver Medical</span> */}
                           </Link>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-6 col-6">
                        <div className="header__menu header-menu-white">
                           <nav id="mobile-menu">
                              <ul>
                                 <li><Link to="/">Home</Link></li>

                                 <li><Link to="#">Support +</Link>
                                    <ul className="submenu">
                                       <li><Link to="/clinic-policy">Clinic Policies</Link></li>
                                       <li><Link to="/faq">FAQ</Link></li>
                                       <li><Link to="/about">About us</Link></li>
                                       <li><Link to="/contact">Contact us</Link></li>

                                    </ul>
                                 </li>

                                 <li><Link to="#">Services +</Link>
                                    <ul className="submenu">
                                       <li><Link to="/book-appointment">Book Appointments</Link></li>
                                       <li><Link to="/immi">Immigration Medical Exam</Link></li>

                                       <li><Link to="/login">AskMedication &trade;
</Link></li>
                                       <li><Link to="/login">AskLabTest &trade;</Link></li>
                                       <li><Link to="/sicknote">Sick Note</Link></li>
                                    </ul>
                                 </li>

                              </ul>
                           </nav>
                        </div>
                        <div onClick={handleShow} className="side-menu-icon d-lg-none text-end">
                           <button className="side-toggle border-0 bg-transparent">
                              <i className="fas fa-bars text-white"></i> </button>
                        </div>
                     </div>

                     {!isImmi ? 
                     <div className="col-xl-6 col-lg-6 col-md-3 d-none d-lg-block d-xl-block">
                        <div className="header-right f-right">
                           <Link to="/login" className="primary_btn btn-icon btn-icon-green">
                           <span><i className="fas fa-sign-in-alt"></i></span>

                              Sign in</Link> 
                           <Link to="/register" className="primary_btn btn-icon btn-icon-white">
                              {/* <i className="fas fa-phone"></i> */}
                              {/* <i className="fas fa-sign-in-alt"></i> */}
                              <i class="fas fa-user-edit"></i>
                              Register</Link>
                        </div>
                     </div> : null}
                  </div>
               </div>
            </div>
         </header>

         <Sidebar show={show} handleClose={handleClose} isImmi={isImmi}/>
      </>
   );
};

export default HomeTwoNavBar;