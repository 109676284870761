import React from 'react';
import HomeTwoSingleService from '../../../components/HomeTwoSingleService/HomeTwoSingleService';

const HomeTwoServicesArea = () => {
   return (
      <>
         <section className="servcies-area gray-bg pt-100 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-4 offset-xl-4 col-lg-10 offset-lg-1">
                     <div className="section-title text-center pos-rel mb-75">
                        <div className="section-icon">
                           <img className="section-back-icon" src="img/section/section-back-icon.png" alt=""/>
                        </div>
                        <div className="section-text pos-rel">
                           <h5>Services</h5>
                           <h2>Our Services</h2>
                        </div>
                        <div className="section-line pos-rel">
                           <img src="img/shape/section-title-line.png" alt=""/>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="row">

                  <HomeTwoSingleService icon="1" number="1" title="Sick Notes" description={
                     <p>Obtain a sick note using telemedicine.<br/>
                     Sick notes provided for time off from work or school.<br/>
                     You will obtain your sick note through email after paying a fee online.<br/><br/><br/><br/>
                     </p>}
                     actionText="Request Sick Note"
                     actionLink='/sicknote'
                  />
                  <HomeTwoSingleService icon="2" number="2" title="Laboratory Testing" description={
                  <p>Obtain basic lab testing using telemedicine. You will obtain your lab requisition through email and will be directed to undergo testing at a LifeLabs location near you.<br/>
                  You will be able to follow up at our clinic or by phone to discuss your lab results with a doctor.<br/>
                  Only if 19-years-old or older.
                  </p>}
                     actionText="Request Lab Test"
                  />
                  <HomeTwoSingleService icon="3" number="3" title="Medication Refill" description={
                  <p>
                  For simple online prescription refills. <br/>  
                  Not for stimulants (ADHD medications), opiates (pain pills) or benzodiazepines (sleeping pills).<br/>
                  Only if 19-year-old or older.<br/><br/> <br/>
                  </p>}
                     actionText="Refill Now"

                  />
                  <HomeTwoSingleService icon="4" number="4" title="Driver's Medical Exam" description={
                  <p>To assess the medical fitness of drivers as required by RoadSafetyBC.<br/>
                  Driver's medical examinations are performed in person at the medical clinic.<br/>
                  You will need to bring a form provided by ICBC for the doctor to fill out.
                  </p>}
                     actionText="Book Now"
                  />
                  <HomeTwoSingleService icon="4" number="5" title="Immigration Medical Exam" description={
                  <p>For Canada, Australia, and New Zealand immigration purposes.<br/>Book directly on our website.<br/>Fast and efficient services. <br/><br/>
                  </p>}
                     actionLink='/immi'
                     actionText="Book Immigration Medical Examination"
                  />
                  <HomeTwoSingleService icon="4" number="6" title="In Person or Telephone Doctor Appointment" description={<p>Come to see the doctor at the medical clinic or one of our doctors will call you.<br/>Free for patients with a valid B.C. Services Card.<br/>
                  $5 booking fee for other provinces except Quebec.
                  </p>}
                     actionText="Book Now"
                  />
                  <HomeTwoSingleService icon="4" number="7" title="Motor Vehicle Crash Appointment" description={
                  <p>Book only if your motor vehicle crash is covered by ICBC.<br/>
                  Either in person at the clinic or the doctor calls you.<br/>
                  You will need an ICBC claim number.<br/><br/><br/><br/>
                  </p>}
                     actionText="Book Now"   
                  />
                  <HomeTwoSingleService icon="4" number="8" title="Private Appointment" description={
                  <p>For tourists, visitors, international students, foreign workers, Quebec residents, and others who do not have a valid B.C. Services Card or equivalent Canadian health card.<br/>
                  See the doctor in person at the clinic or the doctor calls you over the phone.<br/>
                  Payment required.
                  </p>}
                     actionText="Book Now"
                  />
                  


               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoServicesArea;