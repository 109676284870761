import React from 'react';
import {connect} from 'react-redux';
import {BeatLoader} from 'react-spinners';


const Loader = props => {

    const {isLoading,message} = props;


    return (
        <>
            {isLoading ?
            <div id="spinning-control">
                <BeatLoader
                    sizeUnit={'px'}
                    size={20}
                    color={'#42B3E5'}
                    loading={true}
                />
                <span className="text">
                {message? message : ''}
            </span>
            </div> : null }
        </>
    )
}

const mapStateToProps = state => {

    return {
        isLoading : state.loader.isLoading
    }
}

export default connect(mapStateToProps)(Loader);