import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Collapsible from 'react-collapsible';

const SidebarLoggedIn = ({ show, handleClose }) => {

    const Home = <NavLink to="/">Home</NavLink>
   const Services = <NavLink to="#">Services </NavLink>
   const Support = <NavLink to="#">Support</NavLink>


    const Dashboard = <NavLink to="/dashboard">Dashboard</NavLink>
    const MyAccount = <NavLink to="/account">My Account</NavLink>
    const Inbox = <NavLink to="/message">Inbox</NavLink>
    const Logout = <NavLink to="/logout">Logout</NavLink>

    const MyAppointment = <NavLink to="/appointments/me">My Appointment</NavLink>


    return (
        <>

            <div >
                <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
                    <Offcanvas.Header closeButton>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                  <Collapsible trigger={Home} 
                     triggerTagName="div"
                     triggerOpenedClassName="icon_close" 
                     triggerClassName="iconAdd iconAdd-single-item" 
                     open={false}
                     triggerDisabled={true}
                     >
                  </Collapsible>

                  
                  <Collapsible trigger={Support} triggerTagName="div"
                     triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                     <ul className="submenu">
                                       <li><NavLink to="/clinic-policy">Clinic Policies</NavLink></li>
                                       <li><NavLink to="/faq">FAQ</NavLink></li>
                                       <li><NavLink to="/about">About us</NavLink></li>
                                       <li><NavLink to="/contact">Contact us</NavLink></li>

                                    </ul>
                  </Collapsible>




                  <Collapsible trigger={Services} triggerTagName="div"
                     triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                     <ul className="sidebar_sub_menu text-white mt-3">
                        <li><NavLink to="/book-appointment">Book Appointments</NavLink></li>
                        <li><NavLink to="/immi">Immigration Medical Exam</NavLink></li>

                        <li><NavLink to="/login">AskMedication &trade;</NavLink></li>
                        <li><NavLink to="/login">AskLabTest &trade;</NavLink></li>
                        <li><NavLink to="/sicknote">Sick Note</NavLink></li>

                     </ul>

                  </Collapsible>
                    <br/>
                        <Collapsible trigger={Dashboard}
                            triggerTagName="div"
                            triggerOpenedClassName="icon_close"
                            triggerClassName="iconAdd iconAdd-single-item"
                            open={false}
                            triggerDisabled={true}
                        >
                        </Collapsible>

                        <Collapsible trigger={MyAccount}
                            triggerTagName="div"
                            triggerOpenedClassName="icon_close"
                            triggerClassName="iconAdd iconAdd-single-item"
                            open={false}
                            triggerDisabled={true}
                        >
                        </Collapsible>

                        <Collapsible trigger={MyAppointment}
                            triggerTagName="div"
                            triggerOpenedClassName="icon_close"
                            triggerClassName="iconAdd iconAdd-single-item"
                            open={false}
                            triggerDisabled={true}
                        >
                        </Collapsible>

                        <Collapsible trigger={Inbox}
                            triggerTagName="div"
                            triggerOpenedClassName="icon_close"
                            triggerClassName="iconAdd iconAdd-single-item"
                            open={false}
                            triggerDisabled={true}
                        >
                        </Collapsible>

                        <Collapsible trigger={Logout}
                            triggerTagName="div"
                            triggerOpenedClassName="icon_close"
                            triggerClassName="iconAdd iconAdd-single-item"
                            open={false}
                            triggerDisabled={true}
                        >
                        </Collapsible>





                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    );
};

export default SidebarLoggedIn;