

import { SCHEDULE_DID_UPDATE, SET_SCHEDULE_ON, BOOKED, SCHEDULE_RESET } from './type';
import { setAppointmentStateAction } from './appointments';

import AppointmentsForDayModel from '../models/AppointmentsForDayModel';

import Config from '../config';
import store from '../store';

import doctorConstant from '../Constants/DoctorsConstants';

import Utility from '../services/Utility';

// import * as Sentry from '@sentry/react';


import axios from 'axios';

const configAxios = {
    headers:{
        "Content-Type": "application/json"
    }
};

export const setScheduleOnAction = (date, schedule) => async dispatch => {
    if (!date || !schedule) {
        return;
    }

    switch(store.getState().doctors._providerType) {
        // doctor selected
        case doctorConstant.PROVIDER_TYPE_DOCTOR:
            schedule = schedule.filter(s=>{
                return !Utility.isNurseByFirstName(s.doctor.firstName)
            })
            break;
        case doctorConstant.PROVIDER_TYPE_NURSE:
            schedule = schedule.filter(s=>{
                return Utility.isNurseByFirstName(s.doctor.firstName)
            })
            break;
        default:
            break;
    }

    
    dispatch ({
        type: SET_SCHEDULE_ON,
        payload: {date, schedule: new AppointmentsForDayModel(schedule)}
    })
}

export const updateScheduleAction = (update,fetchId) => async dispatch => {
    if (!update) {
        return;
    }

    update.forEach((item) => {
        dispatch(setScheduleOnAction(item.date, item.providers));
    });
    dispatch ({
        type: SCHEDULE_DID_UPDATE,
        payload:fetchId
    })
}

export const bookingConfirmedAction = () => async dispatch => {
    dispatch(setAppointmentStateAction(BOOKED));
}

export const fetchSchedule = (from, to, id, fetchId) => async dispatch => {

    try{
        
        let url = `${Config.service}/doctors/schedule?from=${from.format('YYYY-MM-DD')}&to=${to.format('YYYY-MM-DD')}&id=${id}`;
        // console.log('Fetching schedule',url);
        let res = await axios.get(url);
        let response = res.data;
        // console.log(response);
        if(response.success){
            return dispatch(updateScheduleAction(response.extras,fetchId));
        }
        alert(response.extras.message)

    }catch(err){
        console.log(err)
    }
}

export const bookAppointment = (appointment,selectedType,updatingAppointment) => async dispatch => {

    let url = `${Config.service}/schedule/create`;

    let data = {
        start: appointment.first.start.format(),
        end:appointment.first.end.format(),
        doctor: appointment.first.doctor.id,
        reason: (appointment._storedAppointmentState || {}).reason || '',
        crashYear:(appointment._storedAppointmentState || {}).crashYear || '',
        crashMonth:(appointment._storedAppointmentState || {}).crashMonth || '',
        crashDay:(appointment._storedAppointmentState || {}).crashDay || '',
        contactPreference:(appointment._storedAppointmentState || {}).contactPreference || '',
        selectedType:selectedType || appointment.first.code,
        isReschedule: updatingAppointment ? true : false,
        previousTicklers:(updatingAppointment || {}).ticklers || [],
        stripePaymentToken:(updatingAppointment || {}).stripePaymentToken || null,
        symptom: (appointment._storedAppointmentState || {}).symptom,
        medicationTaken: (appointment._storedAppointmentState || {}).medicationTaken,
        experiencingList: (appointment._storedAppointmentState || {}).experiencingList,
        treatmentList: (appointment._storedAppointmentState || {}).treatmentList
    }
    try{
        let response = await axios.post(url,data,configAxios);
        return response.data;

    }catch(err){
        // Sentry.captureException(err)
        throw err;
    }
}


export const cancelAppointment = (appointment, completion) => async dispatch => {
    // console.log(appointment)
    try{
        let url = `${Config.service}/schedule/${appointment.id}`
        let res = await axios.delete(url)
        completion(true)
    }catch(err){
        completion(false)
    }

}

export const resetScheduleStore = () => dispatch => {
    dispatch({
        type:SCHEDULE_RESET
    })
}