import React from 'react';
import { Helmet } from 'react-helmet';

import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';

import ContactArea from './ContactArea/ContactArea';
import ContactMap from './ContactMap/ContactMap';

const Contact = () => {
    return (
        <>
            <Helmet>
                    <title>Contact Us | South Vancouver Medical Clinic | Vancouver doctor</title>
                    <link rel="canonical" href="https://southvanmed.com/contact" />
                    <meta name="description" content="Contact us at south vancouver medical clinic"/>

                    <meta name="keywords" content="Cancellations Appointment, No Shows, Specialist Referrals, Hospital Investigations,Investigation Results,Controlled Prescriptions,Routine Childhood Vaccinations,Transferring Records, vaccinations" />

            </Helmet>

            {/* <HomeHeader/> */}
            <CommonPageHeader title="Contact Us" subtitle="Contact" />
            <ContactArea/>
            {/* <ContactFormArea/> */}
            <ContactMap/>
            {/* <Footer/> */}
        </>
    );
};

export default Contact;