import React from 'react';
import { Helmet } from 'react-helmet';

import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';

import ClinicPolicyArea from './ClinicPolicyArea';
const ServicesDetails = () => {
    return (
        <>
            <Helmet>
                <title>Clinic Policies | South Vancouver Medical Clinic | Vancouver doctor</title>
                <link rel="canonical" href="https://southvanmed.com/policies" />
                <meta name="description" content="The clinic policies for south vancouver medical clinic" />
                <meta name="keywords" content="Cancellations Appointment, No Shows, Specialist Referrals, Hospital Investigations,Investigation Results,Controlled Prescriptions,Routine Childhood Vaccinations,Transferring Records, vaccinations" />

            </Helmet>

            <CommonPageHeader title="Our Policies" subtitle="Clinic Policies" />
            <ClinicPolicyArea />
        </>
    );
};

export default ServicesDetails;