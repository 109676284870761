import React from 'react';
// import HomeTwoAbout from './HomeTwoAbout/HomeTwoAbout';
// import HomeTwoAppoinmentArea from './HomeTwoAppoinmentArea/HomeTwoAppoinmentArea';
// import HomeTwoBlogs from './HomeTwoBlogs/HomeTwoBlogs';
import HomeTwoCounter from './HomeTwoCounter/HomeTwoCounter';
// import HomeTwoFooter from './HomeTwoFooter/HomeTwoFooter';
import HomeTwoHeroSection from './HomeTwoHeader/HomeTwoHeroSection';
import HomeTwoMissionArea from './HomeTwoMissionArea/HomeTwoMissionArea';

// import HomeTwoNavBar from './HomeTwoNavBar/HomeTwoNavBar';

// import HomeTwoNewsLetter from './HomeTwoNewsLetter/HomeTwoNewsLetter';
import HomeTwoServicesArea from './HomeTwoServicesArea/HomeTwoServicesArea';
// import HomeTwoTeamArea from './HomeTwoTeamArea/HomeTwoTeamArea';
import HomeTwoTestimonials from './HomeTwoTestimonials/HomeTwoTestimonials';


import HowToGetToOurClinic from './HowToGetToOurClinic/HowToGetToOurClinic';

//layout
import TwitterContainer from '../../components/Layout/TwitterContainer';

import Anouncement from '../../components/Banner/Anouncement';



import { Helmet } from 'react-helmet';


const HomeTwo = () => {


    
    return (
        <>
            <Helmet>
                <title>Medical clinic in Vancouver | Vancouver doctor | South Vancouver Medical Clinic</title>
                <meta name="description" content="The South Vancouver Medical Clinic provides in-person and online medical services.
                        We can help you with your medication refill, lab testing needs and ICBC-related medical care. We offer online booking
                        of doctor appointments, urgent care and other family medicine services. Please contact us by email for further details."/>

                <meta name="keywords" content="south Vancouver medical clinic, medical clinic Vancouver, medical clinics in
                    Vancouver,Superstore clinic hours,vancouver clinic, south medical,vancouver clinics, superstore
                    Sunday hours,walk in clinic Vancouver open Sunday, superstore doctors, south van medical clinic,
                    walk in clinic marine drive, south west medical clinic, southwest medical clinic, marine drive walk
                    in clinic,south van medical, marine gateway medical clinic, south medical clinic, health clinic
                    Vancouver,marine drive clinic, south Vancouver clinic, superstore clinic marine drive, superstore
                    doctor clinic,Canadian superstore clinic, superstore medical clinic Vancouver, walk in clinic south
                    Vancouver,medical clinic at superstore,superstore walk in clinic marine drive, doctors office
                    superstore, walk in clinic superstore marine drive,superstore medical clinic marine drive, marine
                    drive doctor,south clinic Vancouver, south Vancouver walk in clinic, south Vancouver medical
                    ,clinic Vancouver bc,superstore south Vancouver,
                    driver fitness medical,driver fitness,
                    driver medical fitness,class 1 medical,commercial driver medical exam,driver's medical exam,
                    driver medical exam,icbc medical exam,icbc medical report,commercial driver physical exam,driver physical exam" />
                <link rel="canonical" href="https://southvanmed.com" />

            </Helmet>
            <HomeTwoHeroSection />
            <HomeTwoServicesArea />
            {/* <TwitterContainer /> */}
            <Anouncement />
            <HomeTwoMissionArea />
            <HomeTwoCounter />
            <HomeTwoTestimonials />
            <HowToGetToOurClinic />
        </>
    );
};

export default HomeTwo;