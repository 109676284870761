import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MedicalInfo } from './MedicalInfo';
import { history } from '../../_helpers';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import { medicalInfoAction } from '../../actions/medicalInfo.action';
import { Container } from 'react-bootstrap';


class SicknoteMedicalInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        // this.handleEdit = this.handleEdit.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    
    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(medicalInfoAction.getByPerson());
    }

    handleEdit= () => {
        history.push('/medicalInfo/edit');
    }

    handleConfirm() {
        const { dispatch } = this.props;

        // dispatch(labtestAction.request());
        history.push('/sick-note');
    }

    render() {
        return (
            <>
            <CommonPageHeader title="Edit Medical Info" subtitle="Medical Info" />

            <Container>
            <div className="sick-note-medical-info-container">
                <h1 className="h5 mb-3 font-weight-normal">First, please confirm your personal medical information:</h1>
                <hr />
                <MedicalInfo />
                <hr className="mb-4" />
                <div className="mb-3 d-flex justify-content-between">
                    <button className="primary_btn bg-secondary mr-3" onClick={this.handleEdit}>Add/Edit</button>
                    {!this.props.noMedicalInfo && <button 
                        className="primary_btn" 
                        onClick={this.handleConfirm}
                        data-test-id="prescription-medical-info__confirm-button"
                    >Confirm</button>}
                </div>
            </div>
            </Container>
            </>
        );
    }
}


SicknoteMedicalInfo.propTypes = {
    dispatch: PropTypes.func.isRequired
};


function mapStateToProps(state) {
    const { noMedicalInfo } = state.medicalInfo;

    return {
        // status,
        noMedicalInfo
    };
}

const temp = connect(mapStateToProps)(SicknoteMedicalInfo);

export { temp as SicknoteMedicalInfo };


