import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getLang } from "./services/lang";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

const resources = {
  en: {
    translation: {
      "offline_text":"Our websites are currently undergoing maintenance. Please login later in order to access our services. We apologize for the inconvenience.",
      "Laboratory Testing": "Laboratory Testing",
      "Request lab testing now": "Request lab testing now",
      "Medication Refill": "Medication Refill",
      "Refill my medication now": "Refill my medication now",
      "Book Appointment": "Book Appointment",
      "Book appointment now": "Book appointment now",
      "Book regular appointment":"Book regular appointment",
      "icbcclinic":"icbcclinic",
      "book_appointment_sentence":'',
      "lab_sentence_1": "Request lab and radiology tests using our website.",
      "lab_sentence_2": "After a review by one of our physicians, the test form is emailed to you (and LifeLabs for lab testing) during our business hours.",
      "Refill up to 5 medications per day using our AskMedication.com website.": "Refill up to 5 medications per day using our AskMedication.com website.",
      "The most convenient way to refill your routine prescriptions!": "The most convenient way to refill your routine prescriptions!",
      "Book an appointment to get a phone call from or see a doctor at the South Vancouver Medical Clinic.": "Book an appointment to get a phone call from or see a doctor at the South Vancouver Medical Clinic.",
      "HOME": "HOME",
      "CLINIC POLICIES": "CLINIC POLICIES",
      "FAQ": "FAQ",
      "BOOK APPOINTMENT": "BOOK APPOINTMENT",
      "LOGIN": "LOGIN",
      "REGISTER": "REGISTER",
      "Welcome to the SouthVanMed Dashboard": "Welcome to the SouthVanMed Dashboard",
      "Our Services,": "Our Services, ",
      "free with a valid BC Services Card (CareCard)": "free with a valid BC Services Card (CareCard)",
      "DASHBOARD": "DASHBOARD",
      "MY ACCOUNT": "MY ACCOUNT",
      "MY MESSAGES": "MY MESSAGES",
      "LOGOUT": "LOGOUT",
      "SouthVanMed Dashboard": "SouthVanMed Dashboard",
      "Email": "Email",
      "Password": "Password",
      "Forgot password": "Forgot password",
      "New to South Vancouver Medical Clinic?": "New to South Vancouver Medical Clinic? ",
      "Sign Up": "Sign Up",
      "Log in": "Log in",
      "Regular": "Regular",
      "Hours": "Hours",
      "Monday": "Monday",
      "Friday": "Friday",
      "Saturdays": "Saturdays",
      "Sundays": "Sundays",
      "and": "and",
      "December": "December",
      "Statutory Holidays": "Statutory Holidays",
      "Telemedicine only": "Telemedicine only",
      "Closed": "Closed",
      "Please note that exceptionally hours may change without notice.": "Please note that exceptionally hours may change without notice.",
      "Address": "Address",
      "We are located on the 2nd floor of the Superstore on Marine Drive at Main Street.": "We are located on the 2nd floor of the Superstore on Marine Drive at Main Street.",
      "Fax": "Fax"
    }
  },
  cn: {
    translation: {
      "offline_text":"我們的網站目前正在進行維護。請稍後登錄以訪問我們的服務。我們對不便表示抱歉。",
      "South Vancouver Medical Clinic":"南溫哥華醫療診所",
      "Laboratory Testing": "開化驗單",
      "Request lab testing now": "開化驗單",
      "Request lab testing": "開化驗單",
      "Sick Note Request":"請求病假條",
      "Re-prescribe Medications": "續開處方藥",
      "Re-Prescribe Medications":"續開處方藥",
      "Refill my medication now": "續開處方藥",
      "Book Appointment": "預約醫生",
      "Book Immigration Medical Exam":"移民體檢預約",
      "Book appointment now": "預約醫生",
      "Book doctor appointment":"預約醫生",
      "Book family medicine appointment":"家庭藥物預約",
      "Book regular appointment":"普通預約",
      "Book ICBC appointment":"ICBC預約",
      "Book driver physical":"司機體檢預約",
      "Book Driver's medical examination":"司機體檢預約",
      "icbcclinic":"icbcclinic",
      "lab_sentence_1": "使用我們的網站開化驗單。",
      "lab_sentence_2": "在我們的醫生審核後，化驗單會在我們的營業時間內通過電子郵件盡快發送給您和 LifeLabs。",
      "Ask the doctor to re-prescribe up to 5 medications per day using our AskMedication.com website.": "使用我們的 AskMedication.com 網站，每天可以向醫生請求續開最多5種處方藥。",
      "The most convenient way to renew your ongoing prescriptions!": "這是續開處方藥最便捷的方式！",
      "Book an appointment to get a phone call from or see a doctor at the South Vancouver Medical Clinic.": "預約面見醫生，或是與醫生電話會診。",
      "Book a regular or ICBC appointment to get a phone call from or see a doctor at the South Vancouver Medical Clinic.":"預約面見醫生，或是與醫生電話會診。",
      "Book an in-person, phone, driver physical or ICBC appointment at the South Vancouver Medical Clinic.":"預約面見醫生，或是與醫生電話會診。",
      "Request a sick note (certificate for time off work or school due to illness) online.":"在線申請病假單（因病休假或休學的證明）。",
      " No need to come to the clinic, you will obtain your sick note through email.": "無需來診所，您將通過電子郵件獲得您的病假單。",
      "Request sick note":"請求病假條",
      "HOME": "主頁",
      "CLINIC POLICIES": "診所政策",
      "FAQ": "常問問題",
      "BOOK APPOINTMENT": "預約",
      "IMMIGRATION MEDICAL EXAM": "移民體檢",
      "LOGIN": "登錄",
      "REGISTER": "登記",
      "Welcome to the SouthVanMed Dashboard": "歡迎來到 SouthVanMed 主頁",
      "Welcome to the South Vancouver Medical Clinic":"歡迎來到South Vancouver醫療診所",
      "Home of ":"主页：",
      " and ":" 和 ",
      "Our Services,": "我們的服務，",
      "free with a valid BC Services Card (CareCard)": "憑有效的 BC 服務卡 (CareCard) 免費",
      "DASHBOARD": "主頁",
      "MY ACCOUNT": "我的帳戶",
      "MY MESSAGES": "我的消息",
      "LOGOUT": "登出",
      "SouthVanMed Dashboard": "SouthVanMed 主頁",
      "Email": "電子郵件",
      "Password": "密碼",
      "Forgot password": "忘記密碼",
      "New to South Vancouver Medical Clinic?": "South Vancouver Medical Clinic 的新用戶？ ",
      "Sign Up": "登記",
      "Log in": "登錄",
      "Regular": "常規",
      "Hours": "時間",
      "Monday": "星期一",
      "Friday": "星期五",
      "Saturdays": "星期六",
      "Sundays": "星期日",
      "and": "及",
      "December": "十二月",
      "Statutory Holidays": "法定假期",
      "Telemedicine only": "僅遠程醫療",
      "Closed": "休息",
      "Please note that exceptionally hours may change without notice.": "請注意，特殊時間可能會更改，恕不另行通知。",
      "Address": "地址",
      "We are located on the 2nd floor of the Superstore on Marine Drive at Main Street.": "我們位於 Main Street 的 Marine Drive 的 Superstore 2 樓。",
      "Fax": "傳真"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLang(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;