import React from 'react';
import HomeTwoSingleTestimonial from '../../../components/HomeTwoSingleTestimonial/HomeTwoSingleTestimonial';
import Slider from "react-slick";

const HomeTwoTestimonials = () => {
   //
   const settings = {
      autoplay: false,
      autoplaySpeed: 10000,
      dots: false,
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               infinite: true,
            }
         },
         {
            breakpoint: 991,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false
            }
         },
      ]

   };
   return (
      <>
         <div className="testimonials-area pt-115 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1 col-md-10 offset-md-1">
                     <div className="section-title text-center pos-rel mb-70">
                        <div className="section-icon">
                           <img className="section-back-icon" src="img/section/section-back-icon.png" alt=""/>
                        </div>
                        <div className="section-text pos-rel">
                           <h5>Testimonials</h5>
                           <h2>Our Happy Clients Says About Us.</h2>
                        </div>
                        <div className="section-line pos-rel">
                           <img src="img/shape/section-title-line.png" alt=""/>
                        </div>
                     </div>
                  </div>
               </div>


               <Slider className='testimonials-activation' {...settings}>

                     <HomeTwoSingleTestimonial image="3" person={'D B'} header={'Great service'} description="I have been going here for years and have had nothing but good experiences and care from all the doctors here.   If you book online there is rarely an extra long wait ."/>
                     <HomeTwoSingleTestimonial image="1"  person={'Bonnie'} header={'Friendly staff'} description="Friendly helpful staff and the Doctor was excellent. Frequently refer friends to this clinic. You may have to wait a bit for your appointment time, but that is representative of a good doctor who takes the time to treat patients."/>
                     <HomeTwoSingleTestimonial image="2" person={'Terry'} header={'Book Online'} description="Great clinic and the wait times for just dropping in range for 10-60 minutes. If I book an appointment wait times never exceed 10 mins. Great Doctors here so I don’t go anywhere else. Waiting is a fact of life, read a book, play some games lol"/>

                  </Slider>

            </div>
         </div>
      </>
   );
};

export default HomeTwoTestimonials;