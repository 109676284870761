import React from 'react';
import { Helmet } from 'react-helmet';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';

import ForgotPasswordArea from './ForgotPasswordArea';

const ForgotPassword = () => {
    return (
        <>
            <Helmet>
                <title>Forgot Password | South Vancouver Medical Clinic | Vancouver doctor</title>
                <link rel="canonical" href="https://southvanmed.com/forgotpassword" />
                <meta name="description" content="South Vancouver medical clinic forgot password page, reset your password now to get access to all services." />
                <meta name="keywords" content="South Vancouver medical clinic sign up, South Vancouver medical clinic forgot password" />

            </Helmet>
            <CommonPageHeader title="Forgot Password" subtitle="Forgot Password" />
            <ForgotPasswordArea />
        </>
    );
};

export default ForgotPassword;