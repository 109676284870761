import React from 'react';
import { Link } from 'react-router-dom';
import Utility from '../../services/Utility'

class Anouncement extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         value:[],
      }
   }


   async componentDidMount(){
      let announcement = await Utility.getCurrentAnouncement();

      console.log(announcement);

      this.setState({value: announcement});
   }



   render() {

      return (
       this.state.value && this.state.value.length > 0 &&  <>
            <section className="about-area pt-115 pb-80">
               <div className="container">
                  <div className="row align-items-center separator pb-110">
                     <div className="col-xl-6 col-lg-6">
                        <div className="medical-icon-brand pos-rel f-left">
                           <img src="img/anouncement/announcement-100.png" alt="" />
                        </div>
                        <div className="about-title mb-20 fix">
                           <h2 className="mb-40">Public Anouncement</h2>
                           <h5 className="pink-color m-0">South Vancouver Medical Clinic</h5>
                        </div>
                     </div>
                     <div className="col-xl-6 col-lg-6">
                        <div className="about-right-content">
                           {this.state.value.map(val=> {
                              return <p>{val}</p>
                           })}
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </>
      );

   }

};

export default Anouncement;