import React, { useState, useRef } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA

import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Config from '../../config';


const SickNoteValidation = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [code, setCode] = useState('');
  const [validationResult, setValidationResult] = useState(null);
  const [recaptcha, setRecaptcha] = useState(''); 
  const [recaptchaError, setRecaptchaError] = useState('');

  const messageRef = useRef(null);

  const handleValidation = async () => {
    // Check if ReCAPTCHA token is empty
    if (!recaptcha) {
      setRecaptchaError('Please complete the ReCAPTCHA verification.');
      return;
    }
  
    try {
      const response = await axios.get(
        `${Config.service}/services/sicknote/validateById/${code}`,
        {
          params: {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            startDate,
            endDate,
            recaptcha, 
          },
        }
      );
  
      // Reset ReCAPTCHA error
      setRecaptchaError('');
  
      if (response.data.success) {
        if (response.data.sickNote) {
          const { user, startTimeOff, endOfTimeOff } = response.data.sickNote;
  
          setValidationResult({
            isValid: true,
            message: {
              firstName: user.firstName,
              lastName: user.lastName,
              startDate: moment(startTimeOff).format('YYYY-MM-DD'),
              endDate: moment(endOfTimeOff).format('YYYY-MM-DD'),
            },
          });
        } else {
          setValidationResult({
            isValid: false,
            message:
              'This sick note is invalid. Please contact info@southvanmed.com for further information.',
          });
        }
      } else {
        if (response.data.message === 'Sick note not found.') {
          setValidationResult({
            isValid: false,
            message:
              'This sick note is invalid. Please contact info@southvanmed.com for further information.',
          });
        } else {
          setValidationResult({
            isValid: false,
            message: response.data.message, // Backend error message
          });
        }
      }
  
      // Scroll to message box and apply bounce animation
      if (messageRef.current) {
        messageRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        messageRef.current.classList.add('animated-zoom-in');
      }
    } catch (error) {
      console.error('Error validating sick note:', error.message);
      setValidationResult({
        isValid: false,
        message: 'Something went wrong!',
      });
    }
  };

  return (
    <>
      <CommonPageHeader title='Sick Note Validation' subtitle='Validation' />
      <Container className='my-5'>
        <Row className='justify-content-center'>
          <Col md={6}>
            <Form className='border p-4 shadow-sm'>
              <Form.Group controlId='formFirstName' className='mb-3'>
                <Form.Label className='fs-5'>First Name:</Form.Label>
                <Form.Control
                  type='text'
                  placeholder="Enter patient's first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId='formLastName' className='mb-3'>
                <Form.Label className='fs-5'>Last Name:</Form.Label>
                <Form.Control
                  type='text'
                  placeholder= "Enter patient's last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId='formStartDate' className='mb-3'>
                <Form.Label className='fs-5'>Start Date:</Form.Label>
                <Form.Control
                  type='date'
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId='formEndDate' className='mb-3'>
                <Form.Label className='fs-5'>End Date:</Form.Label>
                <Form.Control
                  type='date'
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId='formCode' className='mb-3'>
                <Form.Label className='fs-5'>Sick Note Code:</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter the code from the sick note'
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </Form.Group>

              {/* ReCAPTCHA component */}
              <ReCAPTCHA
                className='my-2'
                sitekey={Config.captcha_key} // Replace with your ReCAPTCHA site key
                onChange={(val) => setRecaptcha(val)} // Set ReCAPTCHA token to state
              />
              {recaptchaError && <div className='text-danger'>{recaptchaError}</div>}

              <Button
                style={{ paddingLeft: '50px' }}
                className='primary_btn btn-icon'
                variant='primary'
                onClick={handleValidation}
              >
                Validate Sick Note
              </Button>
            </Form>
            <style>
              {`
                @keyframes zoomIn {
                  0% {
                    transform: scale(0);
                    opacity: 0;
                  }
                  100% {
                    transform: scale(1);
                    opacity: 1;
                  }
                }
      
                .animated-zoom-in {
                  animation-name: zoomIn;
                  animation-duration: 0.5s;
                }
              `}
            </style>
            {validationResult && (
              <div className='mt-4' ref={messageRef}>
                {validationResult.isValid ? (
                  <div className='alert alert-success fs-6' role='alert'>
                    <strong>Validation Successful!</strong> This sick note is
                    valid. Patient's information:{' '}
                    {`Name: ${validationResult.message.firstName} ${validationResult.message.lastName}, Start Date: ${validationResult.message.startDate}, End Date: ${validationResult.message.endDate}`}
                  </div>
                ) : (
                  <div className='alert alert-danger fs-6' role='alert'>
                    <strong>Validation Failed!</strong>{' '}
                    {validationResult.message}
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SickNoteValidation;
