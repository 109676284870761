import React from 'react';
import { Helmet } from 'react-helmet';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';

import MyAppointmentArea from './MyAppointmentArea';

const MyAppointments = () => {
    return (
        <>
            <Helmet>
                <title>My Appointments | South Vancouver Medical Clinic | Vancouver doctor</title>
                <link rel="canonical" href="https://southvanmed.com/appointments/me" />
                <meta name="description" content="South Vancouver medical clinic my appointment page." />
                <meta name="keywords" content="South Vancouver medical clinic my appointment" />

            </Helmet>
            <CommonPageHeader title="My Appointment" subtitle="Appointment" />
            <MyAppointmentArea />
        </>
    );
};

export default MyAppointments;