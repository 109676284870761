import React from 'react';
import { Link } from 'react-router-dom';

const HomeTwoSingleService = ({icon,number,title, subtitle, description, actionText, actionLink}) => {
    return (
        <>
          <div className="col-xl-6 col-lg-6 col-md-12">
             <div className="service-box service-box-2 mb-30 pos-rel">
                <div className="service-thumb">
                   <img src={`img/services/service-icon-${icon}.png`} alt=""/>
                </div>
                <div className="service-content service-content-2">
                   <h6 className="green-color text-up-case letter-spacing mb-20">{subtitle || ''}</h6>

                   <h3><Link to={actionLink || "/login"}>{title}</Link></h3>
                   {description}
                   <Link className="service-link" to={actionLink || "/login"}><i className="fas fa-arrow-right"></i>{actionText ? actionText : 'Read More'}</Link>
                </div>
                <div className="service-number">
                   <h4 className="service-big-number">{`0${number}`}</h4>
                </div>
             </div>
          </div>
        </>
    );
};

export default HomeTwoSingleService;