import React from 'react';
import { Helmet } from 'react-helmet';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';

import MyAccountArea from './MyAccountArea';

const Register = () => {
    return (
        <>
            <Helmet>
                <title>My Account | South Vancouver Medical Clinic | Vancouver doctor</title>
                <link rel="canonical" href="https://southvanmed.com/account" />
                <meta name="description" content="South Vancouver medical clinic my account page." />
                <meta name="keywords" content="South Vancouver medical clinic my account" />

            </Helmet>
            <CommonPageHeader title="My Account" subtitle="Account" />
            <MyAccountArea />
        </>
    );
};

export default Register;