export default class DoctorModel {
    constructor(input) {
        this._providerId = input.id;
        this._firstName = input.firstName;
        this._lastName = input.lastName
    }

    get firstName() {
        return this._firstName;
    }
    get lastName() {
        return this._lastName;
    }
    get fullName() {
        return this._firstName + ' ' + this._lastName;
    }

    get id() {
        return this._providerId;
    }

    get valueOf() {
        return String(this.id);
    }

    // There must be a better way
    isInSet(set) {
        if (set.size < 1) {
            return true;
        }

        for (let item of set) {
            if (item.id === this.id) {
                return true;
            }
        }

        return false;
    }
}