import React from "react";
import HowItWorks from "./HowItWorks";
import PriceList from "./PriceList";
import SampleSickNote from "./SampleSickNote";

import FAQ from "./FAQ";

const SickNoteArea = () => {
  return (
    <>
      <section id="sicknote-area">
        <div className="container">
          <HowItWorks />
          <PriceList />
          <SampleSickNote />
          <FAQ />
        </div>
      </section>
    </>
  );
};

export default SickNoteArea;
