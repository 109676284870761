import React from 'react';
import { Helmet } from 'react-helmet';

import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import HomeFact from '../Home/Home/HomeFact/HomeFact';

import AboutArea from './AboutArea/AboutArea';
import AboutTestimonial from './AboutTestimonial/AboutTestimonial';

const AboutUs = () => {
   return (
      <>
         <Helmet>
            <title>About Us | South Vancouver Medical Clinic | Vancouver doctor</title>
            <link rel="canonical" href="https://southvanmed.com/about" />
            <meta name="description" content="About us at south vancouver medical clinic" />

            <meta name="keywords" content="Cancellations Appointment, No Shows, Specialist Referrals, Hospital Investigations,Investigation Results,Controlled Prescriptions,Routine Childhood Vaccinations,Transferring Records, vaccinations" />

         </Helmet>

         <CommonPageHeader title="About Us" subtitle="About" />

         <AboutArea />
         {/* <AboutCounter/> */}
         {/* <AboutAppoinment/> */}
         {/* <HomeOurTeam/> */}
         <AboutTestimonial />
         <HomeFact />
         {/* <AboutAnalysis/> */}

      </>
   );
};

export default AboutUs;