import React from 'react';
import { Link } from 'react-router-dom';
// import HomeSingleFooterBlog from '../../../components/HomeSingleFooterBlog/HomeSingleFooterBlog';


import TermsPrivacyFee from '../../../components/Layout/TermsPrivacyFee/TermsPrivacyFee';

const HomeTwoFooter = () => {
   return (
      <>
         <footer>
            <div className="footer-top theme-bg">
               <div className="container">
                  <div className="mb-100">
                     <div className="row align-items-center">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                           
                        </div>
                        {/* <div className="col-xl-2 col-lg-3 d-none d-lg-block d-xl-block">
                           <div className="footer-subscribe-title">
                              <span>Subscribe to Our Newsletter</span>
                           </div>
                        </div>
                        <div className="col-xl-6 col-lg-5 col-md-8">
                           <form className="footer-newsletter" action="#">
                              <input type="text" placeholder="Your Email Address...."/>
                                 <button className="primary_btn">subscribe</button>
                           </form>
                        </div> */}
                     </div>
                  </div>
                  <div className="footer-mid pb-50">
                     <div className="row">

                     <div className="col-xl-3 col-md-6">
                     
                           <div className="footer-widget mb-40">
                              <div className="footer-title">
                                 <h3>Address</h3>
                              </div>
                              <div className="clinic-address pe-15">
{/*                           
                                 <HomeSingleFooterBlog image="1" />
                                 <HomeSingleFooterBlog image="2" />
                                 <HomeSingleFooterBlog image="3" /> */}
                                 <p>We are located on the 2nd floor of the Superstore on Marine Drive at Main Street.</p>
                                 <p>350 SE Marine Dr, Vancouver, BC V5X 2S5</p>
                              </div>
                           </div>
                           <div className="footer-logo-2 mb-40">
                              <img src="img/logo/SVM-LOGO5.png" alt=""/>
                           </div>
                        </div>


                     <div className="col-xl-3 col-md-6">
                           <div className="footer-widget mb-40">
                              <div className="footer-title">
                                 <h3>Regular Hours</h3>
                              </div>
                              <div className="clinic-hours pe-15">
                                 <p>Monday - Friday: 9am - 6pm <br/>
                                 Saturdays, Sundays and B.C. Statutory Holidays: Telemedicine<br/>
                                 <br/>
                                 <i>Please note that exceptionally hours may change without notice.
</i>
                                 </p>
                              </div>
                           </div>
                        </div>



                        <div className="col-xl-4 col-lg-6 col-md-6">
                           <div className="footer-widget mb-40">
                              <div className="footer-contact-info-2">
                                 <div className="f-contact-info-box fix mb-30">
                                    <div className="footer-co-icon f-left">
                                       <img src="img/icon/footer-co-icon-fax.png" alt=""/>
                                    </div>
                                    <div className="footer-co-content">
                                       {/* <span>Mon to Fri : 08h30 - 18h00</span> */}
                                       <span>Fax</span>

                                       <h4>(604) 323-0076</h4>
                                    </div>
                                 </div>
                                 <div className="f-contact-info-box fix mb-30">
                                    <div className="footer-co-icon f-left">
                                       <img src="img/icon/footer-co-icon-email.png" alt=""/>
                                    </div>
                                    <div className="footer-co-content">
                                       <span>Email</span>
                                       <h4>info@southvanmed.com</h4>
                                    </div>
                                 </div>
                                 <div className="f-contact-info-box fix mb-30">
                                    <div className="footer-co-icon f-left">
                                       <img src="img/icon/footer-co-icon-social-media.png" alt=""/>
                                    </div>
                                    <div className="footer-co-content">
                                       <span>social network</span>
                                       <ul>
                                          <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                          <li><a href="#"><i className="fab fa-behance"></i></a></li>
                                          <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
                                          <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                          <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="col-xl-2 col-lg-6 col-md-6">
                           <div className="footer-widget mb-40">
                              <div className="footer-title">
                                 <h3>Support</h3>
                              </div>
                              <div className="footer-menu footer-menu-2 fix">
                                 <ul>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                    <li><Link to="/about">About us</Link></li>
                                    <li><Link to="/book-appointment">Book Appointment</Link></li>
                                    <li><Link to="/immi">Immigration Medical Exam</Link></li>
                                 </ul>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
                  <div className="footer-bottom-0">
                     <div className="row">
                        <div className="col-xl-12">
                           <div className="footer-copyright-area text-center">
                              <p className="white-color">Copyright ©2023 <strong><Link className="white-color"
                              to="/">South Vancouver Medical Clinic.</Link></strong> All rights reserved <br/>
                              <TermsPrivacyFee/>
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default HomeTwoFooter;