const config = {
    service: 'https://api.southvanmed.com',
    book_appointment:'https://appointment.southvanmed.com',
    order_labtest:'https://asklabtest.com',
    captcha_key:'6LfIA8UZAAAAACK5A7BA8Qbz-4jNe5mfVWKk5H00',
    askmedication:'https://askmedication.com',
    hasFamilyDoc : "1998",
    noFamilyDoc : "1999",
    askmedApiHost: 'https://api.askmedication.com',
    askmedSecret:'cXpMnLhQ*7#qX1i',
    stripePublicKey:'pk_live_Fkqzpa0SU25HsqoCBbhBSYLD00rBmKxzRA',
    immigration_url:'https://immigration.southvanmed.com',
    homepageUrl:'SouthVanMed.com',
    clinicName:'South Vancouver Medical Clinic',
    service_email:'info@southvanmed.com'
}
export default config;