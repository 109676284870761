import React from 'react';
import moment from 'moment'
import { connect } from 'react-redux';

import { ReplyMessage } from './reply-message';
import { messagesService } from '../../services/messages';
import { messageAction } from '../../actions/message.action';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';


class Messages extends React.Component {

    constructor(props) {
        super();
        this.state = { message : null }
        this.textInput = React.createRef();
    }

    async componentDidMount() {
        const id = this.props.match.params.id
        const message = await messagesService.getMessageById(id);
        this.setState({
            message: message
        });
        this.props.setMessage(message);
    }

    renderReply() {
        const message = this.props.message || {};
        if (message.repliedAt && message.repliedMessage) {
            return <div className='jumbotron'>
                <p className='float-right'>{moment(message.repliedAt).format('MMM DD, YYYY HH:mm')}</p>
                <h3>From: Me</h3>
                <h3>To: Staff</h3>

                <div>
                    <span className='font-weight-bold'>Message: </span>{message.repliedMessage}
                </div>
            </div>
        }
             
        return <button
            className='btn btn-primary mt-1'
            onClick={(e) => {
                e.preventDefault();
                // message.repliedMessage = this.textInput.current.value;
                // messagesService.updateMessageById(message._id, message);
                this.setState({
                    isOpenReply: true
                })
            }}
        >
            Reply
        </button>
    }

    render() {
        const message = this.props.message || {};
        console.log(message);
        return (

            <>
            <CommonPageHeader title={'Message'} subtitle={`message/view`} />
            <div className="moa-message py-2">
                <div className='container'>
                    <div className='jumbotron'>
                        <p className='float-right'>{moment(message.createdAt).format('MMM DD, YYYY HH:mm')}</p>
                        <h3>From: Staff</h3>
                        <h3>To: me</h3>
                        
                        <div>
                           <span className="font-weight-bold">Message: </span>{message.message}
                        </div>
                    </div>

                    {this.renderReply()}
                    {this.state.isOpenReply && <ReplyMessage onDiscard={() => {
                        this.setState({
                            isOpenReply: false
                        })
                    }}/>}
                </div>
            </div>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMessage: (message) => dispatch(messageAction.setMessage(message))
    }
}

const mapStateToProps = (state) => {
    return {
        message: state.message.messageObj
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
