import React from 'react';
import { Helmet } from 'react-helmet';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';

import RegisteArea from './RegisteArea/RegisteArea';

const Register = () => {
    return (
        <>
            <Helmet>
                <title>Sign up | South Vancouver Medical Clinic | Vancouver doctor</title>
                <link rel="canonical" href="https://southvanmed.com/register" />
                <meta name="description" content="South Vancouver medical clinic registration page, register now to get access to all services." />
                <meta name="keywords" content="South Vancouver medical clinic sign up, South Vancouver medical clinic register" />

            </Helmet>
            <CommonPageHeader title="Register here" subtitle="Register" />
            <RegisteArea />
        </>
    );
};

export default Register;