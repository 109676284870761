import React, { Fragment } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {connect} from 'react-redux';
import {
  Elements,
  ElementsConsumer,
  CardElement,
} from "@stripe/react-stripe-js";
import { Button, Container } from "react-bootstrap";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// components

import config from '../../config';
// const stripePromise = loadStripe(config.stripePublicKey);
// import ReactDOM from "react-dom";

// import { setAlert } from '../../actions/alert';

import { setAlert } from "../../services/sweetAlert";

import {setToLoading,setToLoaded} from '../../actions/loader';
import { sickNoteService } from "../../services/sicknote.service";

import { history } from '../../_helpers'
import CommonPageHeader from "../CommonPageHeader/CommonPageHeader";


const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
  //     iconColor: "#c4f0ff",
  //     color: "#fff",
  //     fontWeight: 500,
  //     fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
  //     fontSmoothing: "antialiased",
  //     ":-webkit-autofill": {
  //       color: "#fce883"
  //     },
  //     "::placeholder": {
  //       color: "#87bbfd"
  //     }
  //   },
  //   invalid: {
  //     iconColor: "#ffc7ee",
  //     color: "#ffc7ee"
    }
  }
};

const mapDispatchToProps = {
  setToLoaded,
  setToLoading
}
const mapStateToProps = state => ({
  sickNote: state.sickNote,
  user: state.auth.user,
})
class CheckoutForm extends React.Component {
  constructor() {
    super();
    this.state = {
      isDialog: false
    }
  }
  handleServerResponse = async (response) => {
    if (response.data.success) {
      this.props.setToLoaded();
      
      // setAlert({
      //   message:'Payment success',
      //   icon: 'success'
      // })
      // this.setState({
      //   isDialog: true
      // })
      history.push('/sick-note/payment/success')
      return;
    }

    if (response.data.requires_action) {
      // Use Stripe.js to handle required card action
      let intentConfirm = await this.props.stripe.handleCardAction(
        response.data.payment_intent_client_secret
      );
      if (!intentConfirm || !intentConfirm.paymentIntent) {
        
        setAlert({
          message:'Card validation error',
          icon: 'error'
        })
        return;
      }

      response = await sickNoteService.charge({
        payment_intent_id: intentConfirm.paymentIntent.id,
        sickNote: this.props.sickNote
      });

      return await this.handleServerResponse(response);
    }
    this.props.setToLoaded();
    console.log(response);
    setAlert({
      message:response.data.error || 'Card Failed',
      icon: 'error'
    })
    return;
    
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    
    const { stripe, elements } = this.props;

    if (elements == null) {
      return;
    }
    this.props.setToLoading();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (error){
      this.props.setToLoaded();
      setAlert({
        message:error.message,
        icon:'error'
      })
      return;
    }
      

    let response = await sickNoteService.charge({
      payment_method_id: paymentMethod.id,
      sickNote: this.props.sickNote
    });
    await this.handleServerResponse(response);
  };

  handleDialogClose = () => {
    this.setState({ isDialog: false})
    history.push('/dashboard');
  }

  render() {
    const { stripe } = this.props;
    const { user } = this.props;
    return (
      <Fragment>
        <Dialog open={this.state.isDialog}>
          <DialogTitle>
          Sick note request submitted successfully
          </DialogTitle>
          <DialogContent>
            Doctor Budai will get back to you within 24 hours through email. The email we have on file is {user.email}.
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <div className="checkout-form mt-2">
      
            <form onSubmit={this.handleSubmit}>
              <div className="checkout-form__card">
                <CardElement />
              </div>
              <div className="checkout-form__buttons mt-1">
                <button
                  className="checkout-form__buttons__button primary_btn full-width ml-0"
                  type="submit"
                  disabled={!stripe}
                >
                  Pay now
                </button>
                <button
                  className="mt-1 checkout-form__buttons__button primary_btn bg-secondary full-width ml-0"
                  type="button"
                  onClick={() => {history.push('/sick-note')}}
                >
                  Go back
                </button>
              </div>
            </form>
          
        </div>
      </Fragment>
    );
  }
}
let CheckoutFormConnect = connect(mapStateToProps, mapDispatchToProps) (CheckoutForm)

const InjectedCheckoutForm = () => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutFormConnect stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);

const stripePromise = loadStripe(config.stripePublicKey);

class Payment extends React.Component {
  render() {
    // if user does not have to pay
    // go back to dashboard
    const { sickNote } = this.props;
    let amount = 50;
    if (sickNote.isPrivate)
      amount = 100;
    return (
      <>
      <CommonPageHeader title="Sick Note Payment" subtitle="sick-note/payment"/>
        <div className="payment">
          <Container>
            {/* <h1 className="keywords">Fee</h1> */}
          <p className="mb-2">Please pay the following fee in order to obtain your sick note:</p>
          <span className="payment__fee badge badge-pill badge-success">Fee: ${amount}</span>
          <Elements stripe={stripePromise}>
            <InjectedCheckoutForm />
          </Elements>
          </Container>
        </div>
      </>
    );
  }
}
let temp = connect(mapStateToProps, mapDispatchToProps) (Payment)
export { temp as Payment };
