import moment from 'moment'

export default class AppointmentHistoryModel {
    constructor(input) {
        this._id = input.id;
        this._doctorId = input.doctor;
        this._startTime = moment(input.startTime);
        this._isEditable = input.isEditable;
        this._isCancellable = input.isCancellable
        this._checkIn = input.checkin || null;
        this._description = input.description;
        this._paidAmount = input.paidAmount;
        this._bookingId = input.bookingId;
        this._code = input.code;
        this._isReschedulable = input.isReschedulable;
        this._ticklers = input.ticklers || [];
        this._stripePaymentToken = input.stripePaymentToken || null;
        
    }

    get id() {
        return this._id;
    }
    get doctorId() {
        return this._doctorId;
    }
    get checkinCode() {
        return this._checkIn;
    }

    get time() {
        return this._startTime.format('ddd MMM DD YYYY hh:mm A');
    }
    get start() {
        return moment(this._startTime, 'YYYY-MM-DD HH:mm Z');
    }
    get code() {
        return this._code;
    }

    get isReschedulable(){
        return this._isReschedulable;
    }

    get isEditable() {
        return this._isEditable;
    }
    get isCancellable(){
        return this._isCancellable;
    }
    get description(){
        return this._description;
    }
    get paidAmount(){
        return this._paidAmount
    }
    get bookingId(){
        return this._bookingId;
    }
    get ticklers() {
        return this._ticklers;
    }
    get stripePaymentToken() {
        return this._stripePaymentToken;
    }
}