
import Swal from 'sweetalert2';



export const setAlert = (options) => {
    const {icon = 'warning',message, buttonText,html }= options;
    return Swal.fire({
        icon,
        confirmButtonColor:"#42B3E5",
        text: message,
        html:html,
        confirmButtonText: buttonText || 'Ok'
     })
}