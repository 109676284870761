import React from 'react';
import { Link } from 'react-router-dom'


const AppointmentAreaSvm = () => {

    return (
        <>
            <div className="service-details-area pt-120 pb-100" style={{ backgroundColor: "#f4f9fc" }}>
                <div className="container">
                    <div className="row">

                        <div className="col-xl-12 col-lg-12 mb-80">
                            <article className="service-details-box">

                                <div className="section-title pos-rel mb-45">
                                    <div className="section-icon">
                                        {/* <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" /> */}
                                    </div>
                                    <div className="section-text pos-rel">
                                        <h5 className="green-color text-up-case">Immigration</h5>
                                        <h2>Immigration Medical Examination Appointment</h2>
                                    </div>
                                    <div className="section-line pos-rel">
                                        <img src="img/shape/section-title-line.png" alt="" />
                                    </div>
                                </div>
                                <div className="service-details-text mb-30">
                                    <h4>Important rules regarding immigration medical examinations:</h4>
                                    <p>Most appointments can be cancelled or rescheduled up to six (6) hours before the appointment. However, paid appointments may be rescheduled up to 24 hours before the appointment and cannot be cancelled. There are many other patients waiting for appointments. Failure to cancel or reschedule within these specified timeframes will result in a missed appointment charge of $50.00 ($100.00 for specialist appointments) and forfeiture of any booking fees, if applicable.</p>
                                </div>

                                <Link className="primary_btn btn-icon btn-hide ml-0" to="/immi"><span>+</span>BOOK <div className="hide-on-mobile">YOUR</div> IMMIGRATION <div className="hide-on-mobile">  MEDICAL EXAMINATION </div>NOW</Link>

                            </article>
                        </div>
                        <br />

                        <div className="col-xl-12 col-lg-12 mb-80">
                            <article className="service-details-box">

                                <div className="section-title pos-rel mb-45">
                                    <div className="section-icon">
                                        {/* <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" /> */}
                                    </div>
                                    <div className="section-text pos-rel">
                                        <h5 className="green-color text-up-case">In-Person or telephone</h5>
                                        <h2>In-Person or Telephone Appointment:</h2>
                                    </div>
                                    <div className="section-line pos-rel">
                                        <img src="img/shape/section-title-line.png" alt="" />
                                    </div>
                                </div>
                                <div className="service-details-text mb-30">
                                    <h4>Important rules regarding In-Person or Telephone Doctor Appointments:</h4>
                                    <p>1. EVERYONE with a valid BC Services Card or equivalent out-of-province health card can book an In-Person or Telephone Doctor Appointment, even patients who have never been to our clinic.</p>
                                    <p>2. You may cancel or reschedule your In-Person or Telephone Doctor Appointment up to 6 hours in advance. To do so, click on the button below, log into your account and click on My Appointments.</p>
                                    <p>3. Same day (within 6 hours) appointments CANNOT be cancelled or rescheduled. Not showing up or not being reachable on the telephone number your provide results in a $50.00 No Show charge. Multiple no shows will restrict you from booking appointments at our clinic.</p>
                                    <p>4. Our front desk DOES NOT have the ability to cancel or reschedule In-Person or Telephone Doctor Appointments. Therefore, please do not email our front desk to cancel or reschedule an appointment booked online.</p>
                                    <p>5. There is a $5 non-refundable booking fee for out-of-province health cards. Quebec residents must book a Private Appointment. If you cancel your appointment, the $5 booking fee is not refunded.</p>

                                </div>

                                <Link className="primary_btn btn-icon btn-hide ml-0" to="/login"><span>+</span>BOOK <div className="hide-on-mobile">YOUR</div> IN-PERSON/TELEPHONE<div className="hide-on-mobile">APPOINTMENT NOW</div> </Link>

                            </article>
                        </div>
                        <br />

                        <div className="col-xl-12 col-lg-12 mb-80">
                            <article className="service-details-box">

                                <div className="section-title pos-rel mb-45">
                                    <div className="section-icon">
                                        {/* <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" /> */}
                                    </div>
                                    <div className="section-text pos-rel">
                                        <h5 className="green-color text-up-case">Driver Medical Examination</h5>
                                        <h2>Driver Medical Examination:</h2>
                                    </div>
                                    <div className="section-line pos-rel">
                                        <img src="img/shape/section-title-line.png" alt="" />
                                    </div>
                                </div>
                                <div className="service-details-text mb-30">
                                    <h4>Important rules regarding Driver Medical Examinations:</h4>
                                    <p>1. Driver medical examinations are performed in person at the medical clinic.</p>
                                    <p>2. We will test your vision so bring your glasses or wear contact lenses.</p>
                                    <p>3. You will need to bring a form provided by ICBC for the doctor to fill out.</p>
                                    <p>4. There are private fees and they are non-refundable.</p>
                                </div>

                                <Link className="primary_btn btn-icon btn-hide ml-0" to="/login"><span>+</span>BOOK <div className="hide-on-mobile">YOUR</div> DRIVER MEDICAL <div className="hide-on-mobile">EXAMINATION APPOINTMENT</div> NOW</Link>

                            </article>
                        </div>
                        <br />

                        <div className="col-xl-12 col-lg-12 mb-80">
                            <article className="service-details-box">

                                <div className="section-title pos-rel mb-45">
                                    <div className="section-icon">
                                        {/* <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" /> */}
                                    </div>
                                    <div className="section-text pos-rel">
                                        <h5 className="green-color text-up-case">Motor Vehicle Crash Appointment</h5>
                                        <h2>Motor Vehicle Crash Appointment:</h2>
                                    </div>
                                    <div className="section-line pos-rel">
                                        <img src="img/shape/section-title-line.png" alt="" />
                                    </div>
                                </div>
                                <div className="service-details-text mb-30">
                                    <h4>Important rules regarding Motor Vehicle Crash appointments:</h4>
                                    <p>1. Motor Vehicle Crash appointments deal with vehicle crashes that have an ICBC claim number. You will need to provide your ICBC claim number when attending our clinic.</p>
                                    <p>2. If you settled your claim, please book an <Link to="/login">In-Person or Telephone Doctor Appointment</Link>.</p>
                                    <p>3. When booking an Motor Vehicle Crash appointment, you have the choice to either see the doctor in-person or to ask the doctor to call you back on your telephone.</p>
                                </div>

                                <Link className="primary_btn btn-icon btn-hide ml-0" to="/login"><span>+</span>BOOK <div className="hide-on-mobile">YOUR</div> Motor Vehicle Crash APPOINTMENT NOW</Link>

                            </article>
                        </div>
                        <br />

                        <div className="col-xl-12 col-lg-12 mb-80">
                            <article className="service-details-box">

                                <div className="section-title pos-rel mb-45">
                                    <div className="section-icon">
                                        {/* <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" /> */}
                                    </div>
                                    <div className="section-text pos-rel">
                                        <h5 className="green-color text-up-case">Private pay</h5>
                                        <h2>Private Pay Appointment (if you do not have a valid B.C. Services Card or equivalent):</h2>
                                    </div>
                                    <div className="section-line pos-rel">
                                        <img src="img/shape/section-title-line.png" alt="" />
                                    </div>
                                </div>
                                <div className="service-details-text mb-30">
                                    <h4>The following individuals must pay $250 to obtain a doctor appointment:</h4>


                                    <p>1. Tourists and visitors from outside of Canada</p>

                                    <p>2. International students</p>

                                    <p>3. Foreign workers</p>

                                    <p>4. Quebec residents</p>

                                    <p>5. Individuals whose British Columbia Services Cards are not valid</p>

                                    <p>The South Vancouver Medical Clinic makes it easy for you to book a private pay appointment. You will be able to choose between an in-person visit or receiving a telephone call from the doctor. Please note that there will be a $250 non-refundable private appointment booking fee.</p>

                                    <small className="font-it">* Note: Please do not book a private appointment if you are covered by the British Columbia Medical Services Plan (MSP) or have a valid health card issued by a Canadian province (other than Quebec).
                                    </small>

                                </div>

                                <Link className="primary_btn btn-icon btn-hide ml-0" to="/login"><span>+</span>BOOK <div className="hide-on-mobile">YOUR</div> PRIVATE PAY <div className="hide-on-mobile">APPOINTMENT</div> NOW</Link>

                            </article>
                        </div>
                        <br />




                    </div>
                </div>
            </div>
        </>
    );
};

export default AppointmentAreaSvm;