import React from 'react';
// import { Link } from 'react-router-dom';
// import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import vaccinesFile from '../../assets/files/Vaccines_south_vancouver_clinic.pdf'


const ClinicPolicyArea = () => {

   return (
      <>
         <div className="service-details-area pt-120 pb-100" style={{backgroundColor:"#f4f9fc"}}>
            <div className="container">
               <div className="row">

                  <div className="col-xl-12 col-lg-12">
                     <article className="service-details-box">

                        <div className="section-title pos-rel mb-45">
                           <div className="section-icon">
                              <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" />
                           </div>
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">Cancellation & no shows</h5>
                              <h2>Cancellations and Appointment No Shows.</h2>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-30">
                           <p>Most appointments can be cancelled or rescheduled up to six (6) hours before the appointment. However, paid appointments may be rescheduled up to 24 hours before the appointment and cannot be cancelled. There are many other patients waiting for appointments. Failure to cancel or reschedule within these specified timeframes will result in a missed appointment charge of $50.00 ($100.00 for specialist appointments) and forfeiture of any booking fees, if applicable.</p>
                        </div>

                     </article>
                  </div>
                  <hr/>
                  <div className="col-xl-12 col-lg-12">
                     <article className="service-details-box">

                        <div className="section-title pos-rel mb-45">
                           <div className="section-icon">
                              <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" />
                           </div>
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">Referrals & investigation</h5>
                              <h2>Specialist Referrals and Hospital Investigations</h2>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-30">
                           <p>We will do our best to organize specialist referrals and hospital investigations in a timely manner. However, wait times are often long. Should you not receive a follow-up from us regarding specialist referrals or hospital investigations within two (2) months (or earlier for urgent matters), please inquire at our clinic about the status of your referral.</p>
                        </div>

                     </article>
                  </div>
                  <hr/>
                  

                  <div className="col-xl-12 col-lg-12">
                     <article className="service-details-box">

                        <div className="section-title pos-rel mb-45">
                           <div className="section-icon">
                              {/* <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" /> */}
                           </div>
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">Results</h5>
                              <h2>Investigation Results</h2>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-30">
                           <p>Our clinic policy is to not provide lab results over the telephone. Only our doctors can interpret lab results and the staff will not comment when asked. Please understand that it can take up to ten (10) days or longer for our office to receive results back from various labs or hospitals, and we are not responsible for any such delays.</p>
                           <p>Please always make an appointment with a physician at our clinic after you undergo a hospital investigation in order to discuss results. We will do our best to contact you to book an appointment if your investigation results are abnormal. We will attempt to call you and, if we are unable to reach you or a family member, or to leave a voicemail, we shall send you a letter in the mail. From time to time, we may contact you to discuss relevant normal results. However, note that no follow up system is 100% accurate or failsafe. Therefore, we suggest that you follow up with a doctor at our clinic after every hospital investigation that you undergo.</p>
                           <p>Please note: At times we may need to leave messages on your voicemail (such as the time and place of a specialist referral or a message that lab tests are normal). We will, however, leave only very general information on voicemail. Hence, please provide us with the most secure phone number(s) that you have. If one of your phone numbers cannot or should not be used to leave voicemail messages due to confidentiality concerns, please ask us to remove this phone number from your chart.

                           </p>
                        </div>

                     </article>
                  </div>
                  <hr/>


                  <div className="col-xl-12 col-lg-12">
                     <article className="service-details-box">

                        <div className="section-title pos-rel mb-45">
                           <div className="section-icon">
                              {/* <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" /> */}
                           </div>
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">Prescriptions</h5>
                              <h2>Controlled Prescriptions</h2>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-30">
                           <p>Our doctors do not prescribe oxycodone (Percocet), buprenorphine (Suboxone) or methadone.</p>
                        </div>

                     </article>
                  </div>
                  <hr/>
                  

                  <div className="col-xl-12 col-lg-12">
                     <article className="service-details-box">

                        <div className="section-title pos-rel mb-45">
                           <div className="section-icon">
                              {/* <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" /> */}
                           </div>
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">Vaccinations</h5>
                              <h2>Routine Childhood Vaccinations</h2>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-30">
                           <p>Our clinic suggests strongly that children obtain their routine vaccinations at the South Health Unit located at 6405 Knight Street (at East 49th Avenue). Their phone number is (604) 321-6151. We have found that children are more likely to cooperate with their doctor during subsequent visits to our clinic (as they will not associate our clinic with getting vaccines).</p>
                        </div>

                     </article>
                  </div>
                  <hr/>


                  <div className="col-xl-12 col-lg-12">
                     <article className="service-details-box">

                        <div className="section-title pos-rel mb-45">
                           <div className="section-icon">
                              {/* <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" /> */}
                           </div>
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">Transfer records</h5>
                              <h2>Transferring Records to our Clinic</h2>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-30">
                           <p>When transferring records to our clinic, always provide us with photocopies and not the original documents. We shred all records that are scanned into our electronic medical record database. Hence, the originals should remain in your or your former doctor's possession.</p>
                        </div>

                     </article>
                  </div>
                  <hr/>


                  <div className="col-xl-12 col-lg-12">
                     <article className="service-details-box">

                        <div className="section-title pos-rel mb-45">
                           <div className="section-icon">
                              {/* <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" /> */}
                           </div>
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">Vaccines</h5>
                              <h2>Vaccines Available at our clinic</h2>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-30">
                           <p >
                              Download as per attachment:
                              <a className="btn file-url"
                                 href={vaccinesFile}
                                 target="_blank" >Vaccines.pdf &nbsp; <i className="far fa-file-pdf "></i ></a>
                           </p>
                        </div>

                     </article>
                  </div>

               </div>
            </div>
         </div>
      </>
   );
};

export default ClinicPolicyArea;