import React from 'react';
import HomeTwoSingleMission from '../../../components/HomeTwoSingleMission/HomeTwoSingleMission';

const HomeTwoMissionArea = () => {

   return (
      <>
         <section className="about-area gray-bg pt-115 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-10 col-md-12">
                     <div className="about-title mb-20">
                        <h5 className="pink-color">Our Mission & Vision</h5>
                        <h2>Best Patient Care.</h2>
                     </div>
                     <div className="about-text mission-about-text">
                        <p>Our mission is to provide high-quality healthcare services to individuals and families in our community. We strive to deliver compassionate, patient-centered care that meets the unique needs of each person we serve. We are committed to fostering a collaborative relationship between our patients and healthcare providers to promote the health and wellbeing of our community.</p><br/>
                     </div>
                     <div className="mission-vision-list pr-90">
                        <HomeTwoSingleMission icon="1" description={'Providing high-quality healthcare services to individuals and families in our community.'}/>
                        <HomeTwoSingleMission icon="1" border="border-0" description={'Delivering compassionate, patient-centered care that meets the unique needs of each person we serve.'}/>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 d-lg-none d-xl-block">
                     <div className="mv-right-img pos-rel mb-30">
                        <img src="img/about/about-svm-1.png" alt=""/>
                     </div>
                     {/* <div className="testi-quato-icon about-icon-white d-none d-xl-block">
                        <img src="img/about/about-icon-white.png" alt=""/>
                     </div> */}
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoMissionArea;