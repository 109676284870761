import React from 'react';

const CommonSectionArea = ({ area_header }) => {
   return (
      <>
         <section className="about-area pt-90 pb-90">
            <div className="container">
               <div className="row">
                  {/* <div className="col-xl-6 col-lg-5">
                     {area_header}
                  </div> */}
                  <div className="col-xl-12 col-lg-12">
                     <div className="about-right-side pt-55 mb-30">
                        <div className="about-title mb-20">
                           <h5>About Us</h5>
                           <h2>South Vancouver Medical Clinic.</h2>
                        </div>
                        <div className="about-text mb-50">
                           <p>Welcome to our medical clinic located in Vancouver, British Columbia, Canada. Our clinic is dedicated to providing top-quality healthcare services to all our patients. We have a team of experienced and highly skilled medical professionals, including family physicians, nurse practitioners, and an internal medicine specialist.<br /><br />

                              At our clinic, we offer a wide range of medical services to cater to your needs. We understand that motor vehicle crashes happen, and that's why we can help you with injuries that are covered by ICBC, the provincial insurer. Additionally, we provide immigration medical exams for individuals who are coming to Canada or going to New Zealand. We also offer online prescription refills, physician certificates (sick notes), and lab tests to ensure that you receive the care you need without any hassle.<br /><br />

                              On-site services include physiotherapy, massage therapy, acupuncture, and custom-made orthotics. These services are aimed at helping you achieve optimal health and wellness. Our team of skilled therapists and practitioners work together to provide you with personalized care and attention to ensure that you are on the road to recovery.<br /><br />

                              At our clinic, we are committed to providing you with the highest quality of care. We believe that building a strong patient-doctor relationship is crucial in achieving this. We take the time to listen to your concerns, answer your questions, and provide you with the best possible care. Our clinic is equipped with state-of-the-art medical equipment and facilities to ensure that you receive the best treatment possible.<br /><br />

                              Thank you for choosing our clinic for your healthcare needs. We look forward to serving you and your family.</p>
                        </div>
                        <div className="our-destination">
                           <div className="single-item mb-30">
                              <div className="mv-icon f-left">
                                 <img src="img/about/destination-icon-1.png" alt="" />
                              </div>
                              <div className="mv-title fix">
                                 <h3>Our Mission</h3>
                                 <p>Our mission is to provide high-quality healthcare services to individuals and families in our community. We strive to deliver compassionate, patient-centered care that meets the unique needs of each person we serve. We are committed to fostering a collaborative relationship between our patients and healthcare providers to promote the health and wellbeing of our community.</p>
                              </div>
                           </div>
                           <div className="single-item">
                              <div className="mv-icon f-left">
                                 <img src="img/about/destination-icon-2.png" alt="" />
                              </div>
                              <div className="mv-title fix">
                                 <h3>Our Values</h3>
                                 <p>At our family medicine clinic, we value:<br/>

                                    <span className="clinic-value-title">Compassion:</span> We are committed to providing compassionate care to every patient who walks through our doors. We understand that every person has unique healthcare needs, and we strive to provide individualized care that addresses these needs.<br/><br/>

                                    <span className="clinic-value-title">Respect:</span> We treat every patient with the utmost respect and dignity. We believe that every person deserves to be treated with kindness and understanding, regardless of their background or circumstances.<br/><br/>

                                    <span className="clinic-value-title">Collaboration:</span> We work collaboratively with our patients and other healthcare providers to ensure that we are providing the best possible care. We believe that working together is the key to achieving optimal health outcomes.<br/><br/>

                                    <span className="clinic-value-title">Excellence:</span> We are dedicated to providing excellent healthcare services to our patients. We strive to stay up-to-date with the latest medical advancements and technologies to ensure that we are providing the best possible care.<br/><br/>
                                    <span className="clinic-value-title">Accessibility:</span> We believe that everyone deserves access to high-quality healthcare services. We strive to provide our services in a way that is accessible to everyone, regardless of their background or circumstances.<br/><br/>

                                    <span className="clinic-value-title">Trust:</span> We understand that trust is a crucial component of the patient-provider relationship. We strive to earn the trust of our patients by providing honest, transparent, and reliable healthcare services.<br/><br/>

                                    By upholding these values, we believe that we can provide the highest quality of care to our patients and make a positive impact on the health and wellbeing of our community.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonSectionArea;