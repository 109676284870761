import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { Translation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Button } from 'react-bootstrap';

import AlertDialog from '../../components/Layout/Alert/AlertDialog';

import config from '../../config';

import { getLang } from '../../services/lang';

import socketIOClient from "socket.io-client";

import { history } from '../../_helpers';

import Modal from '../../components/Layout/Modal';

import { addPreferredPharmacy } from '../../actions/pharmacy';

import PharmacySelect from '../../components/Layout/PharmacySelect';
import Utility from '../../services/Utility'

import ServicesSingleItem from './ServiceSingleItem';

import { setAlert } from '../../services/sweetAlert';


const socket = socketIOClient(config.service);


class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.offlineCounter = 0;
        this.url = '';
        this.mode = ''
        this.state = {
            user: this.props.user,
            shouldUpdate: this.props.user ? this.props.user.shouldUpdate : false,
            alert: '',
            isOscarOnline: true,
            isButtonDisabled: false,
            preferredPharmacyList: this.props.pharmacy.list,
            preferredPharmacy: '',
            showPharmacyModal: false,
            url: '',
            mode: ''
        }
    }

    componentDidMount() {

        window.addEventListener('beforeunload', this.componentCleanup.bind(this));

        this._scrollToTop();
        socket.on('connect_error', (err) => {
            // handle server error here
            this._offlineHandler();

        });

        socket.on('oscarOffline', this._offlineHandler.bind(this));
        socket.on('oscarOnline', this._onlineHandler.bind(this));
        // console.log('componentDidMount',this.props.user)

    }

    componentWillUnmount() {
        console.log('componentWillUnmount')
        window.removeEventListener('beforeunload', this.componentCleanup.bind(this));
    }

    componentCleanup() {
        socket.removeAllListeners('oscarOffline')
        socket.removeAllListeners('oscarOnline')
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('componentDidUpdate',this.props.user)
        if (!prevProps.user && this.props.user) {
            this.setState({
                user: this.props.user,
                shouldUpdate: this.props.user.shouldUpdate,
            })
        }
        if (prevProps.pharmacy.list !== this.props.pharmacy.list) {
            console.log('pharmacy changed')
            this.setState({
                preferredPharmacyList: this.props.pharmacy.list
            })
        }
    }

    renderPharmacyMessage() {

        if (this.state.preferredPharmacyList.length == 0) return;


        return <>
            <div id="preferred-pharmacy-container">
                <Row>
                    <Col sm={12} md={12}>
                        <PharmacySelect
                            pharmacyList={this.state.preferredPharmacyList}
                            onChange={this.onChange.bind(this)}
                            name="preferredPharmacy"
                        />
                    </Col>
                </Row>
            </div>
        </>
    }

    _offlineHandler() {
        if (!this.state.isOscarOnline) {
            // if(!this.state.isButtonDisabled){
            //     this.setState({isButtonDisabled:true})
            // }
            return;
        }
        this.setState({ isOscarOnline: false, isButtonDisabled: true })
    }

    _onlineHandler() {
        if (this.state.isOscarOnline) {
            // if(this.state.isButtonDisabled){
            //     this.setState({isButtonDisabled:false})
            // }
            return;
        }
        this.setState({ isOscarOnline: true, isButtonDisabled: false })
    }



    _scrollToTop() {
        scroller.scrollTo("dashbaord-container", {
            duration: 800,
            smooth: true,
            offset: -100
        });
    }

    _closeAlert() {
        this.setState({
            alert: ''
        })
    }

    async serviceClickHandler(url, mode, confirmed) {

        // this.setState({
        //     isButtonDisabled: true,
        // })

        this.url = url;
        this.mode = mode;

        // save mode and url for debugging purposes
        Utility.saveErrorDetails('serviceLinkHandlerClick', `${this.props.user.id},${mode},confirmed:${confirmed}`);

        if (mode == 'labtest') {
            url += `/${getLang()}`
        } else if ((mode == 'askmedication' || mode == 'appointment') && !confirmed) {

            // check user's preferred pharmacy and log down request;
            let pharmacy = await Utility.getUserPharmacyStatus();
            if (!pharmacy) {
                // show modal
                this.togglePharmacyModal(true);
                return;
            }
        }

        let { user } = this.props;
        if (user.blocked) {

            setAlert({icon:'danger',message: 'Your account has been blocked. Please walk-in to the clinic during our regular business hours.'});

            this.setState({
                // alert: 'Your account has been blocked. Please walk-in to the clinic during our regular business hours.',
                isButtonDisabled: false
            })


            return;
        }

        window.location.href = url;
    }

    _isAppointmentVisible(appType) {
        // appType ENUM I O D P M G

        let { appointmentType } = this.props.user;
        // console.log(appointmentType)
        return appointmentType.some(e => {
            return e.code == appType && e.status == 'enabled'
        })
    }

    togglePharmacyModal(val) {
        this.setState({
            showPharmacyModal: val
        })
    }
    cancelPharmacyModal() {
        this.setState({
            isButtonDisabled: false,
            showPharmacyModal: false
        })
    }
    async confirmPharmacyModal() {
        let selectedPharmacy = this.state.preferredPharmacy;
        if (!selectedPharmacy) {

            setAlert({icon:'danger',message: 'Please select the preferred pharmacy.'});
            
            // this.setState({
            //     alert: 'Please select the preferred pharmacy.'
            // })
            return;
        }
        this.togglePharmacyModal(false);

        let data = {
            demographicNo: this.props.user.demographicNo,
            pharmacy: selectedPharmacy
        }
        await Utility.addPreferredPharmacy(data);
        this.serviceClickHandler(this.url, this.mode, true)
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }




    render() {
        if (!this.props.user) return null;

        if (this.state.shouldUpdate) return (<Redirect to={'/account'}></Redirect>)


        let userVisibility = (this.props.user.appVisibility && this.props.user.appVisibility.length !== 0) ? this.props.user.appVisibility : ['appointment'];
        let isAskmedBlocked = this.props.user.askmedBlocked;
        /*
        const isBcUser = this.props.user && this.props.user.careCard.province == "British Columbia";
        // const isBcUser = true;
        let {year,month,day} = this.props.user.birthday;
        let dob = moment(`${year}-${month}-${day}`);
        let years = moment().diff(moment(dob),'years');
        const isNotRestricted = (years >18 && isBcUser ) ? true : false;
        const isAskmedicationShown = ((this.props.user.eligibility == 'R' && years >18) || isNotRestricted ) ? true : false;
        const isLabtestShown = ((this.props.user.eligibility == 'R' && years >18) || isNotRestricted ) ? true : false;
        */

        const isLabtestShown = userVisibility.includes('asklabtest');
        const isAskmedicationShown = (userVisibility.includes('askmedication') && !isAskmedBlocked);

        let token = localStorage.getItem('accessToken');

        const linkLabtest = `${config.order_labtest}/auth/${token}`;
        const linkAppointment = `${config.book_appointment}/auth/${token}/null`;

        const linkMedication = `${config.askmedication}/auth/${token}`;

        let appointmentText = 'Book an appointment at the South Vancouver Medical Clinic.';
        let offlineText = <Translation>
            {
                t => <>{t('offline_text')}</>
            }
        </Translation>;

        const linkAppointmentICBC = `${config.book_appointment}/auth/${token}/I`;
        const linkAppointmentDriverPhysical = `${config.book_appointment}/auth/${token}/D`;


        let height = 130;

        if (this._isAppointmentVisible('I')) height += 56;

        if (window.innerWidth < 1160) height = '';

        return (
            <>

                <div id="dashbaord-container" className='gray-bg'>
                    <Modal
                        header="Before proceeding, please select your preferred pharmacy"
                        backdrop={'static'}
                        show={this.state.showPharmacyModal}
                        // hideCloseButton={true}
                        dialogClassName={'modal-70w'}
                        setShow={this.togglePharmacyModal.bind(this)}
                        confirm={this.confirmPharmacyModal.bind(this)}
                        cancelFunc={this.cancelPharmacyModal.bind(this)}
                        message={this.renderPharmacyMessage()}
                        confirmText="Confirm pharmacy"
                        cancelText='Close'
                        closeButton={false}
                        keyboard={false}
                        footerClass={"justify-content-center"}
                    />
                    {/* <h5 className="center">
                        <Translation>
                            {
                                t => <>{t('South Vancouver Medical Clinic')}</>
                            }
                        </Translation>
                    </h5> */}
                    <div className="bottom-line"></div>
                    <AlertDialog message={this.state.alert} onClose={this._closeAlert.bind(this)} />

                    <div className="row">
                        {isLabtestShown && <ServicesSingleItem image="1" title={<Translation>
                            {
                                t => <>{t('Lab and Radiology Testing')}</>
                            }
                        </Translation>}
                            serviceLinks={
                                <button className="service-link" onClick={() => this.serviceClickHandler(linkLabtest, 'labtest')} disabled={!this.state.isOscarOnline || this.state.isButtonDisabled}>
                                    <Translation>
                                        {
                                            t => <>{t('Request lab and radiology testing')}</>
                                        }
                                    </Translation>
                                </button>
                            }
                            description={
                                <p className={!this.state.isOscarOnline ? 'text-danger' : ''}>
                                    {this.state.isOscarOnline ?
                                        <>
                                            <span className="text-danger">
                                            <Translation>
                                                {
                                                    t => <>{t('lab_sentence_1')}</>
                                                }
                                            </Translation>
                                            </span>
                                            <span className={""}>&nbsp;
                                                <Translation>
                                                    {
                                                        t => <>{t('lab_sentence_2')}</>
                                                    }
                                                </Translation>
                                            </span>
                                        </> : offlineText}
                                    <br />
                                    <br/>

                                </p>
                            }
                        />}



                        {isAskmedicationShown && <ServicesSingleItem
                            image="2"
                            title={<Translation>
                                {
                                    t => <>{t('Re-prescribe Medications')}</>
                                }
                            </Translation>}
                            serviceLinks={<button className="service-link" onClick={() => this.serviceClickHandler(linkMedication, 'askmedication')} disabled={!this.state.isOscarOnline || this.state.isButtonDisabled}>
                                <Translation>
                                    {
                                        t => <>{t('Re-Prescribe Medications')}</>
                                    }
                                </Translation>
                            </button>}

                            description={<p className={!this.state.isOscarOnline ? 'text-danger' : ''}>
                                {this.state.isOscarOnline ? <>
                                    <Translation>
                                        {
                                            t => <>{t('Ask the doctor to re-prescribe up to 5 medications per day using our AskMedication.com website.')}</>
                                        }
                                    </Translation>
                                    <span className={"text-danger"}>&nbsp;
                                        <Translation>
                                            {
                                                t => <>{t("The most convenient way to renew your ongoing prescriptions!")}</>
                                            }
                                        </Translation>
                                    </span></> : offlineText}
                            </p>}
                        />}

                        <ServicesSingleItem
                            image="3"
                            title={<Translation>
                                {
                                    t => <>{t('Sick Note Request')}</>
                                }
                            </Translation>}
                            serviceLinks={<button className="service-link" onClick={() => history.push('/medicalInfo')}
                                disabled={!this.state.isOscarOnline || this.state.isButtonDisabled}
                            >
                                <Translation>
                                    {
                                        t => <>{t('Request sick note')}</>
                                    }
                                </Translation>
                            </button>}

                            description={<p className={!this.state.isOscarOnline ? 'text-danger' : ''}>
                                {this.state.isOscarOnline ? <>
                                    <Translation>
                                        {
                                            t => <>{t('Request a sick note (certificate for time off work or school due to illness) online.')}</>
                                        }
                                    </Translation>
                                    <span className={"text-danger"}>
                                        <Translation>
                                            {
                                                t => <>{t(' No need to come to the clinic, you will obtain your sick note through email.')}</>
                                            }
                                        </Translation>
                                        <br/>
                                        <br/>
                                        <br/>


                                    </span>
                                </> : offlineText}
                            </p>}
                        />

                        <ServicesSingleItem
                            image="4"
                            title={<Translation>
                                {
                                    t => <>{t('Book Appointment')}</>
                                }
                            </Translation>}
                            serviceLinks={
                                <>
                                    <button className="service-link" onClick={() => this.serviceClickHandler(linkAppointment, 'appointment')}
                                        disabled={!this.state.isOscarOnline || this.state.isButtonDisabled}
                                    >
                                        <Translation>
                                            {
                                                t => <>{t('Book an in-person, phone or motor vehicle crash appointment')}</>
                                            }
                                        </Translation>
                                    </button>

                                    {/* {this._isAppointmentVisible('I') ? <button className="service-link" onClick={() => this.serviceClickHandler(linkAppointmentICBC, 'appointment')} disabled={!this.state.isOscarOnline || this.state.isButtonDisabled}
                                    >
                                        <Translation>
                                            {
                                                t => <>{t('Book ICBC appointment')}</>
                                            }
                                        </Translation>
                                    </button> : null} */}
                                    {/* {this._isAppointmentVisible('I') ? <br /> : null} */}
                                    {this._isAppointmentVisible('D') ? <button className="service-link" onClick={() => this.serviceClickHandler(linkAppointmentDriverPhysical, 'appointment')} disabled={!this.state.isOscarOnline || this.state.isButtonDisabled}
                                    >
                                        <Translation>
                                            {
                                                t => <>{t('Book Driver\'s medical examination')}</>
                                            }
                                        </Translation>
                                    </button> : null}

                                    <a className="service-link"  href="https://immigration.southvanmed.com" disabled={!this.state.isOscarOnline || this.state.isButtonDisabled}
                                    >
                                        <Translation>
                                            {
                                                t => <>{t('Book Immigration medical examination')}</>
                                            }
                                        </Translation>
                                    </a>
                                </>
                            }

                            description={<p className={!this.state.isOscarOnline ? 'text-danger' : ''}>
                                {this.state.isOscarOnline ? <>
                                <span className="text-danger">
                                    <Translation>
                                        {
                                            t => <>{t(appointmentText)}</>
                                        }
                                    </Translation>
                                    </span>
                                </> : offlineText}
                            </p>}
                        />


                    </div>


                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        pharmacy: state.pharmacy
    }
}

const mapDispatchToProps = {
    addPreferredPharmacy
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);