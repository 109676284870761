import React from 'react';
import { Helmet } from 'react-helmet';
import { Translation } from 'react-i18next';

import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import LoginAreaV2 from './LoginArea/LoginAreaV2';

const Login = () => {
   const  renderTitle = () =>  {

        return (
            <>
                <div className="title-section">
                    {/* <h3 className="welcome-title keywords">
                        <Translation>
                            {
                                t => <>{t('South Vancouver Medical Clinic')}</>
                            }
                        </Translation>
                    </h3> */}

                    <h3 className="content-title mt-30">
                        <Translation>
                            {
                                t => <>{t('Home of ')}</>
                            }
                        </Translation>
                        <a className="small keywords under-line color-red" href="https://AskMedication.com">AskMedication.com</a>
                        {/* <Translation>
                            {
                                t => <>{t(' and ')}</>
                            }
                        </Translation>

                        <a className="small keywords under-line color-red" href="https://ICBCclinic.com">ICBCclinic.com</a> */}
                    </h3>

                </div>
            </>
        )
    }

    return (
        <>
            <Helmet>
                <title>South Vancouver Medical Clinic | Login</title>
                <link rel="canonical" href="https://southvanmed.com/login" />

                <meta name="description" content="The South Vancouver Medical Clinic login page " />
            </Helmet>
            <CommonPageHeader title="Welcome to South Vancouver Medical Clinic" subtitle="Login" titleSmall={renderTitle()} />
            <LoginAreaV2 />
        </>
    );
};

export default Login;