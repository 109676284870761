import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SampleSickNote = () => {
  return (
    <>
      <div className="sample-sicknote">
        <div className="row">
          <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
            <div className="section-title text-center pos-rel mb-75">
              <div className="section-icon">
                {/* <img className="section-back-icon" src="img/section/section-back-icon.png" alt="" /> */}
              </div>
              <div className="section-text pos-rel">
                <h5>How the Sick Note looks like</h5>
                <h2>Sample Sick Note</h2>
              </div>
              <div className="section-line pos-rel">
                <img src="img/shape/section-title-line.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        {/* <h1>Sample Sick Note</h1> */}
        <div className="sample-sicknote-container">
          <img src="img/sicknote/Sample-Sick-Note.png" alt="sample-sicknote" />
        </div>
      </div>
      <div className="center pb-40">
        <Link
          className="primary_btn w-30 btn-icon btn-hide no-icon-btn"
          to="/validation"
        >
          Check if a SICK NOTE is valid{" "}
        </Link>
      </div>
    </>
  );
};

export default SampleSickNote;
