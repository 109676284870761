
import React from 'react';


const FaqArea = () => {

    return (
        <>
            <div id="faq">
                <div className="row py-2">
                    <div className="col-lg-12">
                        <div id="bootstrap-accordion_12" className="panel-group accordionStyles accordion" role="tablist" aria-multiselectable="true">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse1_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">1. The website tells me 'user not found' when I try to log in.
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse1_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> Please note that our online booking system has been updated. Please navigate to the homepage of our website at <a href="http://southvanmed.com/" className="file-url">https://southvanmed.com/</a>, click “Book Appointment” on the top right, and then select the type of appointment you require. Then click on the “Sign Up” button and register as a new user if you have not yet registered on our new booking system. Please note that accurately entering your information is vital to the appointment booking process, as the website will assume you are a private pay patient and request payment should your name, date of birth, B.C. Services Card (CareCard) information be inaccurate.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse2_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">2. When I try to login, the system tells me “email already in use”.
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse2_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4 "> All accounts must have a unique email address as the email address is used to authenticate a login. Please note that Gmail allows you to use alias emails. For example, if your email address is <span className="file-url">myemail@gmail.com</span> and your child’s name is Charlie, you can use <span className="file-url"> myemail+charlie@gmail.com </span>as the email address on our booking system. All emails including confirmation emails from us will be delivered to <span className="file-url">myemail@gmail.com</span>. This way, you can easily create a new login for your dependents without needing a new email inbox.

                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>


                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse3_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">3. I want to use my email address for my child’s appointment, but my email is already in use. What should I do?
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse3_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> All accounts must have a unique email address as the email address is used to authenticate a login. Please note that Gmail allows you to use alias emails. For example, if your email address is <span className="file-url">myemail@gmail.com</span> and your child’s name is Charlie, you can use <span className="file-url"> myemail+charlie@gmail.com </span>as the email address on our booking system. All emails including confirmation emails from us will be delivered to <span className="file-url">myemail@gmail.com</span>. This way, you can easily create a new login for your dependents without needing a new email inbox.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse4_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">4. I need to book an appointment for my child/family member. Can I just use my login information to book for others?
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse4_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> Because online accounts are directly linked to accounts at the clinic, every patient must have a unique online profile with a unique email address. Therefore, the account of a parent or relative cannot be used to book for others. Please note that appointments booked for others in this fashion will be cancelled. Furthermore, if appointments are repeatedly booked for others, the user’s online account may be suspended or terminated.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse5_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">5. When I try to register, the website notifies me that there is an existing account under my B.C. Services Card (CareCard).
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse5_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> The online booking system only allows unique B.C. Services Cards (CareCards) numbers. If you get this error, then you have already registered in our system. Please go back to the booking webpage, look for the "Forgot Password" button, and follow the prompts to reset your password.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse6_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">6. I just made my account but I cannot log in.
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse6_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> Users of all new accounts will receive a confirmation email in their inbox. Please locate this email and confirm your account in order to activate your login. If you do not see this email in your inbox, please also check your Spam folder, Junk folder, and Promotions tab.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse7_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">7. Every time I try to book an appointment, I am asked to pay a $250 fee.
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse7_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> If you receive a prompt for payment, it is possible your current MSP coverage does not cover the service, as our system checks patient eligibility automatically. If you think this is an error, please email us at <span className="file-url">info@southvanmed.com </span>for assistance and clarification. Please note that if you choose to pay the $250 appointment booking fee, it will not be refunded.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse8_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">8. Are you able to issue me a fit to fly letter for travel purposes?
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse8_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> Fit to fly letters are dependent on your consultation with the doctor. We will not be able to guarantee prior to your visit whether or not this can be issued, as the doctor will have to screen you for symptoms and recent history of sickness. If the doctor is able to write a fit to fly certificate for you, please keep in mind there is a standard private paperwork charge of $50.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>


                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse9_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">9. Can I get tested for COVID-19 at your clinic?
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse9_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> COVID-19 tests cannot be done at this location and must be performed at a designated COVID-19 testing site. Please refer to the VCH COVID-19 testing webpage for more information: <a href="http://www.vch.ca/covid-19/covid-19-testing" className="file-url">http://www.vch.ca/covid-19/covid-19-testing</a>. Please keep in mind that, at present, COVID-19 testing cannot be performed on asymptomatic patients at these sites.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse10_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">10. How do I find out/obtain a copy of my lab results?
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse10_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> Patients can register for MyCareCompass through Lifelabs to obtain direct access to their lab results. Our staff will contact patients with abnormal lab results to remind them to book a follow-up visit with one of our physicians for review. However, our clinic’s policy is that patients should independently follow up for all results. This way, it is ensured that all results are accounted for.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="card">
                                        <div class="card-header" role="tab" id="headingOne">
                                            <a role="button" class="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse21_22" aria-expanded="false" aria-controls="collapse1">

                                                <h4 class="mbr-fonts-style display-7">11. How do I get a copy of my medical records?
                                                    <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse21_22" class="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div class="panel-body">
                                                <p class="mbr-fonts-style panel-text  display-4">You may obtain a copy of your medical records at our clinic for $75.00. Please send an email to <span class="file-url">info@southvanmed.com</span> requesting your medical records. Please specify your first name, last name, date of birth and healthcare card number (if available). Then send a $75.00 e-transfer to <span class="file-url">info@southvanmed.com</span>. You will not need to set up a security question as this email has auto-deposit, however we ask that you specify the reason for the e-transfer in the “memo” or “note” field. We will then prepare for you a CD that contains your medical records within ten (10) business days. We will email you when your records are ready for pickup. You will need to come to the clinic and show your picture ID to our receptionist in order to retrieve the CD containing your medical records. We do not mail CDs containing medical records due to the sensitivity of the information contained within these CDs. When you attend the clinic to see a doctor, our doctors are also able to print out up to ten (10) pages of your medical records for $1.00 per page. If your requested medical records can be accessed through LifeLabs’ MyCareCompass portal, our doctors will not print out records but ask instead that you obtain these records through the portal. Please note that we are unable to email you medical records and that our receptionists cannot print out medical records.

                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div className="col-lg-6">

                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse11_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">12. I missed the doctor’s phone call, what should I do now?
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse11_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> Our doctors will call the next appointment on their schedule if they are unsuccessful in contacting you, and will attempt to contact you again once they have finished with their next patient. If you have (or you think you have) missed your appointment, you may send an email to our inbox at<span className="file-url"> info@southvanmed.com</span>  to request to be recalled. Please include your primary contact number in your email, so that we may cross-check this information with our records. Please keep in mind that just like in-person appointments, some patients may take longer than others, and your telehealth appointment may not be initiated at the exact time of your booking.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>


                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse12_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">13. I need to refill my regular prescription, what should I do?
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse12_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> Patients seeking to refill a medication they have had prescribed before may use our online refill service at <a href="http://askmedication.com" className="file-url">https://askmedication.com/</a>. Please keep in mind this service can only be used by patients 19 years of age or older, and cannot be used to refill prescriptions of stimulants (e.g., ADHD medications), opiates (e.g., pain pills) or benzodiazepines (e.g., sleeping pills).
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse13_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">14. My pharmacy didn’t receive the prescription that the doctor just prescribed.
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse13_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> Please send us an email at <span className="file-url">info@southvanmed.com</span> with your name and date of birth, as well as your preferred pharmacy, so we may double check this with your doctor.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse14_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">15. I want to cancel my appointment, can I just send you an email to cancel?
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse14_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> Appointments must be cancelled on the online booking platform, and must be cancelled at least six (6) hours prior to your appointment time. Cancellation requests sent by email (regardless whether they are within the cancellation grace period) will be considered no shows. Please note that privately paid (i.e., when a fee was paid) appointments can be rescheduled within 24 hours by sending an email to <span className="file-url">info@southvanmed.com</span>. Privately paid appointments cannot be cancelled once made.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black pr-5" data-toggle="collapse" data-core="" href="#collapse15_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">16. I can’t cancel my appointment in my online account, what should I do?
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse15_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> Appointments can be cancelled by logging in to the booking site and navigating to the tab labelled ”My Appointments.“ Should you have trouble locating this tab, please try to maximize the window or set your mobile browser to PC view or “Desktop Site”. Appointments cannot be cancelled less than six (6) hours prior to the appointment time. Privately paid (i.e., when a fee was paid) appointments cannot be cancelled.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse16_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">17. I forgot my password, how can I get it back?
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse16_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> Passwords cannot be retrieved, but can be reset by using the "Forgot Password" link found on the login screen. Our system will ask for some identifying information from you for verification and security purposes. Once your identity is verified, you will receive an email with a code you may use to reset your password.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse17_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">18. The doctor issued a sick note to me. How can I get it?
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse17_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4">Sick notes are charged for at rates between $40 to $50. If you were issued a sick note during your telehealth visit, an invoice will be emailed to you. Once it has been paid, your sick note will be emailed to you automatically. Should you have trouble finding this invoice in your inbox, please also check your Spam folder, Junk folder, and Promotions tab. If you are certain you have not received this invoice,please email us at <span className="file-url">info@southvanmed.com</span> to request that it be resent.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>


                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse18_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">19. Why can't I change my BC Service Card Number, my date of birth, my name?
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse18_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> For security purposes, personal information on a patient’s account cannot be changed by the patient. If you wish to update information on your record, please send us an email at <span className="file-url">info@southvanmed.com</span> and request for your information to be updated. Please be aware that we may ask you some verifying questions for identification purposes before proceeding and that at times you may be asked to come in person to the clinic for proof-of-identity purposes.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse19_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">20. What should I do if my name or date of birth on the BC Service Card (CareCard) doesn’t match my actual information?
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse19_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> Please contact MSP to have this information updated and request that a new card be mailed to you. Meanwhile, should you need to register and book an appointment ASAP, please register using the information shown on your BC Services Card. Once this information has been updated with MSP and you have received your new card, you may email us and we will update this information on your chart accordingly.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse20_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">21. What happens if I am late for my appointment?
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse20_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> Patients who are late for their appointment may lose their spot to the next patient on the schedule and will have to wait for the next available spot, if available. Patients who are significantly late for their appointment may be considered as a no show and will be checked in as a walk-in, and will have to wait their turn on a first-come-first-served basis.
                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>


                                    <div className="card">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed pr-5 text-black" data-toggle="collapse" data-core="" href="#collapse21_21" aria-expanded="false" aria-controls="collapse1">

                                                <h4 className="mbr-fonts-style display-7">22. What happens if I don’t show up for my appointment?
                                                    <div><span className="toggle-icon inactive sign mbr-iconfont"></span>
                                                    </div>
                                                </h4>
                                            </a>
                                        </div>
                                        <div id="collapse21_21" className="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                            <div className="panel-body">
                                                <p className="mbr-fonts-style panel-text  display-4"> Patients who fail to attend their appointment will be subject to a no show fee of $50. Failure by patients to attend multiple appointments may result in a loss of booking privileges, suspension of the patient’s account, or termination of the patient’s online account.

                                                    <br /></p>
                                            </div>

                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </>
    );
};

export default FaqArea;