import React from "react";
import config from '../../config';



const ToImmigration = () => {

    console.log('bookonline')
    window.location.href = config.immigration_url;
    return null;
};

export default ToImmigration;